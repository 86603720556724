const locale = {
  translations: { // Mesmo valor usado nas configurações (defaultNS)
    rotes: {
      budgeter: 'calculadora',
    },
    pages: {
      intro: 'Intro',
      description: 'Descrição',
      solution: 'Solução',
      enhancements: 'Melhorias',
      technology: 'Tecnologia',
      contact: 'Contato',
    },
    home: {
      first: 'ENHANCING',
      second: 'TECHNOLOGY',
      hintMobile: 'Deslize para continuar...',
      hintDesk: 'Role para continuar...',
      hintIconMobile: 'Deslize',
      hintIconDesk: 'Role',
      callToAction: 'Quero tentar'
    },
    second: {
      first: '<span>Viva o</span> <span>futuro agora</span>',
      second: '<span>Crie seus</span> próprios produtos digitais',
      paragraph: `
        Kolabs é um <span>ecossistema online colaborativo</span> que cria projetos para as várias áreas da sociedade.
      `,
      paragraph2: `
        Somos pesquisadores e aplicamos soluções inovadoras para a <span>aceleração da comunidade tecnológica</span>. Aprenda sobre a programação autônoma com <span>foco no usuário</span>.
      `,
      listItem1: `Otimizamos processos`,
      listItem2: `Criamos novas tecnologias`,
      listItem3: `Automatizamos rotinas`,
      listItem4: `Compartilhamos inovações`,
    },
    third: {
      title: '_ O conceito',
      paragraph: `A realidade é muito subjetiva, pois <span>vivemos dentro de nossas mentes</span>. Estamos imersos em problemas rotineiros e precisamos abstrair para <span>entender o que está acontencendo no mundo</span>.`,
      paragraph2: `<span>Vamos fazer um exercício simples:</span><br /> Onde você estiver, esqueça seus problemas por um momento e olhe ao seu redor...<br/> Observe que a maioria das coisas que você vê são <span>focadas na função</span>.`,
      paragraph3: `Objetos de uso diário <span>não interagem com você de forma inteligente</span> ou trazem benefícios além dos que foram propostos. Eles não estão integrados com nada e não sabem nada a seu respeito para trazer facilidades à sua vida.`,
      paragraph4: `<span>Tudo isso pode ser melhorado...</span>`,
      more: 'Saiba mais',
    },
    thirdMore: {
      title: '_ O manifesto',
      paragraph: `Há um pensamento coletivo de que tarefas burocráticas são desgastantes. O uso de <strong>apps empresariais é repetitivo e não possui atrativos</strong>. Tentativas de gamificação são aplicadas, mas badges e pontuações logo perdem sua função de estímulo.`,
      paragraph2: `Ainda que você ame seu trabalho, lidar com o mesmo sistema se torna desgastante após um tempo.`,
      paragraph3: `Existem interações que façam o seu trabalho passar mais rápido e ser satisfatório? <strong>Como se sentir bem usando um app?</strong>`,
      paragraph4: `Inserir conceitos fundamentais de UX pode ajudar a resolver, mas têm <strong>aspectos psicológicos que devem ser considerados</strong>, como por exemplo suas necessidades básicas e motivações.
      Por aqui, nós fazemos isso embasados em pesquisas científicas.`,
      paragraph5: `Tornamos a <strong>tecnologia amigável</strong> e a usamos produtivamente, facilitando a vida das pessoas para que elas possam se divertir enquanto realizam as tarefas árduas do dia a dia.`,
      paragraph6: `<strong>Esse é o objetivo de uma usabilidade imersiva</strong> e é o potencial que vemos a programação cumprir.`,
    },
    fourth: {
      title: '_ Problemas?',
      paragraph: `Como <span>digitalizar</span>, <span>integrar</span> e <span>produzir</span> facilidades?`,
      paragraph2: `Como ter segurança, dinheiro e ajudar pessoas em tarefas cotidianas?`,
      paragraph3: `O mundo real poderia ser <span>repleto de tecnologia</span>, caso tivessemos tempo e conhecimento (garantidos por facilidades tecnológicas) para criar.`,
      paragraph4: `Levando isso para a interação de um cliente com um sistema tecnológico: <br/><strong>A interação que buscamos é a <span>imersão<span></strong>.`,
      more: 'Saiba mais',
    },
    fourthMore: {
      title: '_ Foco no usuário',
      paragraph: `O poder de escolha com base em <strong>emoções e memórias</strong> é o que nos difere das máquinas - criadas apenas para cumprir tarefas com foco na função. “Se acontecer A, faça B, senão não faça”.`,
      paragraph2: `Se você é humano, você não é orientado à função. Somos complexos, repletos de sentimentos e muito reativos!`,
      paragraph3: `O ambiente, acontecimentos e demais humanos moldam nosso comportamento, logo <strong>os comandos não podem ser previstos</strong>: “Se acontecer A, um dia você fará B e no dia seguinte pode estar desmotivado ou conhecer alguém que mudou sua opinião”.`,
      paragraph4: `Isso quer dizer que podem desistir de usar sua plataforma só por estar nublado. As possibilidades podem incluir infinitas variáveis.`,
      paragraph5: `O segredo é otimizar a <strong>usabilidade</strong> para o ser humano se sentir bem, em oposição à pura eficiência.`,
      paragraph6: `A identificação do cliente com o cenário é uma arte e nem todos têm o domínio dela! É fundamental incluir <strong>elementos divertidos e emocionantes</strong> encontrados em fontes de lazer (filmes, jogos e outros) e aplicá-los em atividades produtivas do mundo real.`,
    },
    fifth: {
      title: '_ TECNOLOGIAS',
      paragraph: 'Criar tecnologia proporciona uma sensação incrível, mas exige <span>paixão</span>, <span>coragem</span> e <span>autoconfiança</span>. Com as ferramentas certas, projetos tecnológicos se tornam reais. As suas criações ganham vida, estando presentes em um futuro inevitável.',
      item1: {
        title: 'Desenvolvimento Web',
        text: `Otimize seus processos manuais, garanta mais precisão nas ações realizadas e aumente a capacidade de produção e atendimento de um negócio, permitindo a expansão saudável do negócio.`,
      },
      item2: {
        title: 'Automações',
        text: `Pegue um processo manual e o torne independente de interação humana, programando um maquinário para realizá-lo. Mapeie as atividades, ineficiências e desperdícios para eliminá-los.`,
      },
      item3: {
        title: 'Big Data',
        text: `A análise de dados coletados pela empresa cruzados com outros dados do mercado, disponíveis no meio digital. Modelos estatísticos que garantem maior previsibilidade nas decisões.`,
      },
      item4: {
        title: 'Blockchain e cloud',
        text: `Crie novas relações digitais em seu ambiente empresarial. Armazene e compartilhe seus dados em um ambiente seguro e digital com autenticação e autorização distribuída.`,
      },
      more: 'Saiba mais',
    },
    fifthMore1: {
      title: '_ Desenvolvimento Web',
      paragraph: `Existem diferentes <strong>softwares de gestão</strong> disponíveis no mercado, sistemas preparados para auxiliar em atividades do setor comercial, logístico, financeiro, entre outros.`,
      paragraph2: `O antigo processo de gestão gera resultados lentos e <strong>é necessário adotar a tecnologia para otimizar esses processos.</strong>`,
      paragraph3: `O uso dessas plataformas <strong>otimizam a produtividade</strong> de todo o time, possibilitando automatizar processos manuais, garantem precisão nas ações e aumentam a capacidade de atendimento, permitindo <strong>a expansão saudável do negócio</strong>.`,
      paragraph4: `É possível encontrar vários fornecedores para criar apps que atendam às necessidades da empresa e o orçamento estipulado para investimento em tecnologia.`,
    },
    fifthMore2: {
      title: '_ Automações',
      paragraph: `A automação é a porta de entrada para começar a inserir <strong>recursos tecnológicos</strong> em uma empresa.`,
      paragraph2: `O objetivo é <strong>mapear as atividades de uma empresa</strong> e <strong>simplificar</strong> processos repetitivos, automatizando-os para <strong>reduzir custos operacionais</strong>.`,
      paragraph3: `A ideia é <strong>tornar processos manuais indepentes de interação humana</strong>, programando um sistema para executá-lo e torná-lo passível de erros.`,
      paragraph4: `Elimine a tarefa manual, <strong>otimize processos</strong>, <strong>economize seu tempo</strong> e da sua equipe. Assim, você poderá <strong>realizar contratações mais especializadas</strong> que se dediquem à implementações de novas tecnologias para seu negócio.`,
      paragraph5: `Você irá adentrar em um mercado que funciona full time, <strong>minimizando desafios</strong> e <strong>aumentando a competitividade</strong>.`,
      paragraph6: `Além disso, um <strong>sistema integrado</strong> disponibiliza dados entre setores, removendo a lentidão e o gargalo de comunicação.`
    },
    fifthMore3: {
      title: '_ Big Data',
      paragraph: `O termo Big Data significa “grande quantidade de dados” e é o <strong>armazenamento de todos os dados</strong> passíveis de captação em todas as escalas e de todas as formas possíveis.`,
      paragraph2: `<strong>Analisa-se as ações</strong> que um <strong>usuário</strong> realiza, tanto em um website, quanto em <strong>seus hábitos</strong> usando softwares e aplicativos.`,
      paragraph3: `Podemos salvar decisões, opções e preferências, assim, acertamos as <strong>preferências de seus clientes</strong>. Você mira melhor na <strong>publicidade</strong> e sugere <strong>produtos ou serviços</strong> que realmente os interessam.`,
      paragraph4: `Big Data também serve para <strong>analisar as métricas de sua empresa</strong>, realizando comparações entre informações para acompanhar seus progressos. Você começa a tomar decisões baseadas em <strong>dados reais</strong>.`,
      paragraph5: `<strong>Sistemas inteligentes</strong>, tecnologia de ponta e uma boa lógica de captura <strong>são essenciais</strong> para iniciar com a utilização de Big Data em seu negócio.`
    },
    fifthMore4: {
      title: '_ Blockchain e Cloud',
      paragraph: `Blockchain é um termo comumente relacionado com criptomoedas (como a Bitcoin, por exemplo), porém sua <strong>real funcionalidade</strong> vai muito além disso.`,
      paragraph2: `Ela veio de muitos estudos e da necessidade do <strong>compartilhamento de dados constante com mais privacidade</strong>, em um <strong>sistema 100% seguro e descentralizado</strong>.`,
      paragraph3: `<strong>Este formato revolucionou o mundo digital!</strong>`,
      paragraph4: `Nosso <strong>algoritmo de desenvolvimento de software</strong> é baseado em <strong>blockchain</strong> e separa as tarefas, atribuindo-as para os recursos mais qualificados. Automaticamente, com <strong>machine learning aplicada às decisões</strong> e garantia de segurança e interoperabilidade entre setores e tarefas.`,
      paragraph5: `Como o fluxo foi baseado nos mesmos princípios da blockchain, hoje conseguimos estabelecer uma <strong>rede própria, segura e 100% em nuvem</strong>.`,
    },
    sixth: {
      title: '_ COMO RESOLVER?',
      paragraph: 'Melhorias são realizadas para <span>facilitar a vida</span>, <span>economizar dinheiro</span> ou trazer <span>vantagem competitiva</span>. Ao implementar apps ou automatizar tarefas, elas irão se encaixam em estratégias como as abaixo:',
      item1: {
        title: 'Eficiência',
        text: `A palavra não possui atrativos sem estar conectada a uma função. Ela surge com a função que você otimiza. O que você pode tornar mais eficiente? Você pode acelerar processos, reduzir erros e retrabalhos.`,
      },
      item2: {
        title: 'Redução de custos',
        text: `Investir em tecnologia é gastar para economizar. É evidente que você economiza muito recurso reduzindo custos operacionais. Além de que, existe também a opção de licenciar sua criação.`,
      },
      item3: {
        title: 'Interação com clientes',
        text: `O processamento de linguagem está avançando e naturalizou o uso de assistentes de voz e texto. Chatbots fornecem respostas rápidas ao cliente, melhorando interações.`,
      },
      item4: {
        title: 'Vantagem competitiva',
        text: `Investir em tecnologia é ter vantagem competitiva. Criar funcionalidades que enriqueçam seu portfólio de produtos rapidament, impulsiona o mercado e assusta a concorrência.`,
      },
      more: 'Saiba mais',
    },
    sixthMore1: {
      title: '_ Eficiência',
      paragraph: `A palavra <strong>"eficiência"</strong> só faz sentido se somada a função que você otimiza. São os <strong>avanços na tecnologia</strong> que fazem as coisas funcionarem de forma eficiente em empresas, reduzindo erros e retrabalhos.`,
      paragraph2: `As empresas precisam se <strong>otimizar</strong> completamente, mas existem <strong>prioridades</strong> dentro de cada uma e as <strong>melhorias</strong> feitas em uma area impactam vários processos de alguma forma.`,
      paragraph3: `Se você não sabe como começar, <strong>entenda a dor do mercado</strong>, dos clientes e logo estara percebendo problemas maiores no mundo que podem ser resolvidos por você.`,
      paragraph4: `<strong>O que pode se tornar mais eficiente? Como otimizar o mercado? Como trazer eficiência nas atividades das empresas do ramo?</strong>`,
    },
    sixthMore2: {
      title: '_ Redução de custos',
      paragraph: `É evidente a <strong>economia ao reduzir custos</strong> operacionais com <strong>automatizações.</strong>`,
      paragraph2: `Existem empresas que usam <strong>robôs</strong> para <strong>atender os clientes</strong> em busca de suporte, <strong>reduzindo</strong> em <strong>20% os custos</strong> em um dos setores mais caros de uma organização.`,
      paragraph3: `Inicia o contato com uma <strong>inteligência artificial</strong> e, em último caso, passa para o atendente humano. Geralmente o próprio <strong>robô soluciona a demanda</strong>.`,
      paragraph4: `Não é necessário contratar um sistema pronto ou criar uma plataforma inteira do zero.`,
      paragraph5: `<strong>Inicie</strong> lentamente a digitalização da sua empresa <strong>com automatizações</strong>, mas não deixe de <strong>olhar para o futuro</strong>.`,
      paragraph6: `Mirar apenas em girar o balanço ou simplesmente investir em serviços terceirizados é negligenciar a capacidade do seu negócio de criar as próprias soluções. <strong>O que é seu, é seu</strong>.`,
    },
    sixthMore3: {
      title: '_ Interação com clientes',
      paragraph: `Com tantos avanços em <strong>processamento de linguagem</strong>, há uma naturalização de <strong>assistentes de voz</strong> e texto, não é mais tão estranho <strong>conversar com um robô</strong>!`,
      paragraph2: `<strong>Chatbots usam AI</strong> para aprimorar respostas, reunindo métricas e analisando resultados, repetem as tarefas com o <strong>melhor resultado</strong> e enviam respostas rápidas para melhores interações.`,
      paragraph3: `<strong>O trabalho manual</strong> também é <strong>otimizado</strong>, com a pré-seleção do problema, pois as respostas são afuniladas e o atendente reduz o tempo das conversas.`,
      paragraph4: `À medida que a <strong>tecnologia de aprendizado</strong> de máquina e a AI amadurecem, as <strong>possibilidades</strong> de personalização se tornam mais comuns e <strong>acessíveis</strong> para as pequenas empresas, pois a demanda se torna maior.`,
      paragraph5: `Resumindo: <strong>A adesão das pequenas empresas nos serviços digitais populariza o digital</strong>.`,
    },
    sixthMore4: {
      title: '_ Vantagem Competitiva',
      paragraph: `A melhor coisa sobre investir em tecnologia é obter <strong>vantagem competitiva</strong>! Seus concorrentes não esperam que você <strong>evolua</strong> mais rápido do que eles, então você salta, enriquecendo seu portfólio com tecnologia.`,
      paragraph2: `A <strong>corrida tecnológica</strong> já está acontecendo, então todos esperam que você use certa funcionalidade, quando há muitas outras para se explorar.`,
      paragraph3: `O fato é que <strong>organizações usam a tecnologia como arma competitiva há séculos</strong> e quanto antes sua empresa começar a usá-la, mais vantagem você terá.`,
      paragraph4: `Por exemplo, <strong>big data</strong> é um desafio para as empresas, mas é possível <strong>filtrar e manipular dados</strong> de várias maneiras. Os dados de clientes, produtos, vendas e marketing são usados para <strong>reduzir o tempo de lançamento de um novo produto, conquistar contratos e mirar em parceiros potenciais</strong>, iniciando uma grande vantagem competitiva sobre seus concorrentes.`,
      paragraph5: `As <strong>tecnologias de suporte</strong> estão se desenvolvendo, então é bom saber o que esperar desses avanços.`,
    },
    contact: {
      title: '_ CONTACT US',
      subtitle: '<span>Quer conversar?</span><br/> Estamos à disposição para responder suas dúvidas sobre nossas pesquisas. Podemos também te ajudar com o <span>desenvolvimento de novas tecnologias</span>!',
      send: 'Enviar',
      errorTitle: 'Algo deu errado.',
      error: 'Sua mensagem não pôde ser enviada.',
      successTitle: 'Sucesso!',
      success: 'Sua mensagem foi enviada com sucesso!',
      btnError: 'Ok',
      btnSuccess: 'Me leve para o início',
      name: 'Nome',
      namePlaceholder: 'Digite seu nome',
      email: 'E-mail',
      emailPlaceholder: 'Digite seu e-mail',
      message: 'Mensagem',
      messagePlaceholder: 'Digite sua mensagem',
    },
    general: {
      more: 'Saiba mais sobre...',
      prev: 'Anterior',
      next: 'Próximo'
    }
  }
}

export default locale;
