import { BudgeterCard } from 'components/budgeter-card'
import { DataType } from 'budget'
import * as S from './budgeter-page-style'
import { useState } from 'react'

type BudgeterPageProps = {
  data: DataType
}

export function BudgeterPage ({ data }: BudgeterPageProps) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const handleSelectClick = (id: string) => {
    if (
      data.select_type === 'single'
      && !Boolean(selectedOptions.find(item => item === id)
    )) {
      setSelectedOptions([id])
    } else if (
      data.select_type === 'multiple'
      && !Boolean(selectedOptions.find(item => item === id)
    )) {
      const newSelectedOptions = [...selectedOptions, id]
      setSelectedOptions(newSelectedOptions)
    } else {
      let newSelectedOptions = []

      for (let i = 0; i < selectedOptions.length; i++) {
        if (selectedOptions[i] !== id) {
          newSelectedOptions.push(selectedOptions[i])
        }
      }

      setSelectedOptions(newSelectedOptions)
    }
  }

  return (
    <S.Container>
      <S.PageTitle>{data.title}</S.PageTitle>

      <S.Wrapper>
        <S.Grid length={data.budget_options.length}>
          {data.budget_options.map((option, index) => (
            <div
              key={index}
              onClick={() => handleSelectClick(option.id)}
              style={{ position: 'relative' }}
            >
              <BudgeterCard
                selected={Boolean(selectedOptions.find(item => item === option.id))}
                data={option}
              />
            </div>
          ))}
        </S.Grid>
      </S.Wrapper>
    </S.Container>
  )
}
