import styled from 'styled-components/macro'
import { Button, ContainerInnerSlide, ParagraphEnd } from 'components/shared/styles'

export const Container = styled(ContainerInnerSlide)`
  padding: 0 24px 0;
  justify-content: center;
  ga

  > div:not(:first-child) {
    padding: 0;
  }

  @media (max-width: 800px) {
    padding-top: 8em;
    justify-content: start;
  }

  @media (max-height: 580px) and (min-width: 768px) {
    padding-top: 4em;
    justify-content: start;
  }

  @media (max-height: 650px) and (max-width: 768px) {
    padding-top: 8em;
    justify-content: start;
  }

  @media (min-width: 1120px) {
    gap: 2em;
  }
`

export const Paragraph = styled(ParagraphEnd)`
  margin-bottom: 0;
  margin-top: 1.5em;

  @media (min-width: 800px) {
    letter-spacing: .44px;
    line-height: 24px;
  }

  @media (min-width: 1120px) {
    margin-top: 0;
    max-width: 640px;
  }

  span:first-child {
    font-size: 1.125rem;
  }

  span:first-child {
    background: -webkit-linear-gradient(37deg,#00A8A8 6.1%,#6889FF 113.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }

  span {
    background: -webkit-linear-gradient(309.83deg, #00A8A8 6.1%, #6889FF 13.98%, #CC33FF 65.12%, #FF00E5 104.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: .75em;
  width: 100%;
  max-width: 41.625em;
  margin: 0 auto;

  > div:first-child {
    display: grid;
    gap: .75em;

    @media (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContactFormLabel = styled.label`
  font-size: .875rem;
  font-weight: 700;
  text-transform: uppercase;
`

export const Input = styled.input`
  width: 100%;
  height: 48px;
  font-size: .75rem;
  padding: 0 1.25em;
  background: rgba(244, 244, 244, 0.7);
  backdrop-filter: blur(25px);
  box-shadow: inset 0px 4px 15px -4px rgba(50, 73, 132, 0.15), inset 0px -5px 15px 3px rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  border: none;
  font-family: "Open Sans", sans-serif;

  &::placeholder {
    color: #000;
  }

  &:focus-visible {
    outline: none;
  }
`

export const StyledArea = styled.textarea`
  background: rgba(244, 244, 244, 0.7);
  backdrop-filter: blur(25px);
  box-shadow: inset 0px 4px 15px -4px rgba(50, 73, 132, 0.15), inset 0px -5px 15px 3px rgba(255, 255, 255, 0.8);
  height: 6.438em;
  border-radius: 10px;
  font-size: .75rem;
  padding: 1.25em;
  border: none;
  resize: none;
  font-family: "Open Sans", sans-serif;

  &::placeholder {
    color: #000;
  }

  &:focus-visible {
    outline: none;
  }

  @media (min-width: 900px) {
    height: 9.688em;
  }
`

export const SendButton = styled(Button)`
  width: 100%;
  max-width: 160px;
  margin-left: auto;
  margin-top: 7px;
`

export const Alert = styled.div`
  width: 100%;
  max-width: 480px;
  color: #646464;
  font-size: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.75);
  text-align: center;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
  letter-spacing: .44px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.85) 96.33%);
  box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.15), inset 0px -5px 6px rgba(102, 0, 153, 0.15);
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 400ms cubic-bezier(0.2, 0.8, 0.4, 1);
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  place-items: center;

  svg {
    margin-bottom: 1.25em;
  }
`

export const AlertTitle = styled.h2`
  color: #262626;
  font-size: 1.5rem;
  margin-bottom: 1em;
`

export const AlertBtn = styled.button`
  background-color: #fff;
  box-shadow: 0px -5px 15px -3px #FFFFFF, 0px 5px 15px -3px rgba(50, 73, 132, 0.15);
  border-radius: 10px;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 40px;
  width: 100%;
  margin-top: 2.5em;
  max-width: 320px;
  letter-spacing: .75px;
  line-height: 0;
  padding: 0 1em;
  text-transform: uppercase;
  color: #262626;
  cursor: pointer;
`
