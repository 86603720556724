import { createGlobalStyle } from "styled-components"
import { App } from "app"
import ModalProvider from "components/contexts/modal-context"

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 100%;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }

  html,
  body,
  #root {
    min-height: 100%;
  }

  body {
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    font-family: 'Rajdhani', system-ui, sans-serif;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  #root {
    isolation: isolate;
  }
`

export function Root () {
  return (
    <ModalProvider>
      <GlobalStyle />

      <App />
    </ModalProvider>
  )
}
