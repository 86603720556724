import { useTranslation } from 'react-i18next'
import * as S from './intro-style'
import { ReactComponent as CheckIcon } from 'images/icons/check.svg'
import { DecagonBlur, DecagonSVG, TopGradient } from 'components/shared/styles'

export function Intro () {
  const { t } = useTranslation()

  return (
    <>
      <S.Wrapper>
        <S.ColumnWrapper>
          <S.StyledTitle dangerouslySetInnerHTML={{__html: t('second.first')}} />

          <S.StyledSubTitle dangerouslySetInnerHTML={{__html: t('second.second')}} />

          <S.StyledParagraph dangerouslySetInnerHTML={{__html: t('second.paragraph')}} />
        </S.ColumnWrapper>

        <S.ColumnWrapper>
          <S.StyledParagraph dangerouslySetInnerHTML={{__html: t('second.paragraph2')}} />

          <ul>
            <li><CheckIcon />{t('second.listItem1')}</li>
            <li><CheckIcon />{t('second.listItem2')}</li>
            <li><CheckIcon />{t('second.listItem3')}</li>
            <li><CheckIcon />{t('second.listItem4')}</li>
          </ul>
        </S.ColumnWrapper>

        <S.ColumnWrapper>
          <S.DecagonWrapper>
            <DecagonSVG />

            <DecagonBlur />
          </S.DecagonWrapper>
        </S.ColumnWrapper>
      </S.Wrapper>

      <TopGradient />
      <S.Background />
    </>
  )
}
