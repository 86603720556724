import { useContext, useEffect, useState } from 'react'
import { ReactComponent as CloseIcon} from 'images/icons/close.svg'
import { ModalContext } from 'components/contexts/modal-context'
import { SplideSlide } from '@splidejs/react-splide'
import { ReactComponent as SwipeIcon } from 'images/icons/swipe.svg'
import { ReactComponent as ArrowIcon } from 'images/icons/arrow.svg'

import * as S from './modal-style'
import { useTranslation } from 'react-i18next'

type ModalProps = {
  navigation?: boolean
  content: {
    title: string
    paragraphs: string[]
  }
}

export function Modal ({ content, navigation }: ModalProps) {
  const { setIsModalOpen, setModalId, modalId } = useContext(ModalContext)
  const { t } = useTranslation()
  const [minWidth, setMinWidth] = useState(false)
  const [pageNum, setPageNum] = useState('')

  useEffect(() => {
    setMinWidth(window.innerWidth > 799 ? true : false)

    window.addEventListener('resize', () => {
      setMinWidth(window.innerWidth > 799 ? true : false)
    })
  }, [minWidth])

  const activePage = (e: any) => {
    const activeSlide = e.Components.Pagination.items.filter((item: any) => {
      return item.button.classList.contains('is-active')
    })

    if (activeSlide.length > 0) {
      let number = 'I'

      if (activeSlide[0].page === 1) number = 'II'

      setPageNum(number)
    }
  }

  return (
    <S.Content className='modal'>
      {minWidth ? (
        <S.Wrapper>
          <S.LearnMore>{t('general.more')}</S.LearnMore>

          <S.Title>{content.title}</S.Title>

          <S.Article>
            {content.paragraphs.map((item, index) => (
              <S.Paragraph dangerouslySetInnerHTML={{__html: item}} key={index} />
            ))}
          </S.Article>

          {navigation && (
            <S.Navigation>
              {modalId !== 0 && (
                <S.NavButton
                  style={{ marginRight: 'auto' }}
                  onClick={() => setModalId(prev => prev - 1)}
                >
                  <ArrowIcon style={{ transform: 'scaleX(-1)' }} />

                  {t('general.prev')}
                </S.NavButton>
              )}

              {modalId !== 3 && (
                <S.NavButton
                  style={{ marginLeft: 'auto' }}
                  onClick={() => setModalId(prev => prev + 1)}
                >
                  {t('general.next')}

                  <ArrowIcon />
                </S.NavButton>
              )}
            </S.Navigation>
          )}

          <S.BtnWrapper>
            <S.Close onClick={() => setIsModalOpen(prev => !prev)}>
              <CloseIcon />
            </S.Close>
          </S.BtnWrapper>
        </S.Wrapper>
      ) : (
        <S.Wrapper>
          <S.Header>
            <S.LearnMore>{t('general.more')}</S.LearnMore>

            <S.Title>{content.title}</S.Title>

            <S.Page>{pageNum}</S.Page>
          </S.Header>

          <S.SplideArticle
            onPaginationUpdated={activePage}
            onPaginationMounted={activePage}
            options={{
              height: '100%',
              arrows: false,
              pagination: true,
              gap: '1em',
            }}
          >
            <SplideSlide>
              {content.paragraphs.slice(0, 3).map((item, index) => (
                <S.Paragraph key={index} dangerouslySetInnerHTML={{__html: item}} />
              ))}
            </SplideSlide>

            <SplideSlide>
              {content.paragraphs.slice(3, content.paragraphs.length).map((item, index) => (
                <S.Paragraph key={index} dangerouslySetInnerHTML={{__html: item}} />
              ))}
            </SplideSlide>
          </S.SplideArticle>

          <S.BtnWrapper>
            <S.Close onClick={() => setIsModalOpen(prev => !prev)}>
              <CloseIcon />
            </S.Close>
          </S.BtnWrapper>

          <S.SwipeWrapper>
            <SwipeIcon />

            <span>{t('home.hintIconMobile')}</span>
          </S.SwipeWrapper>

          <S.Texture />
        </S.Wrapper>
      )}
    </S.Content>
  )
}
