import styled from 'styled-components/macro'
import { Splide } from '@splidejs/react-splide'

export const Content = styled(Splide)`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1280' height='720' viewBox='0 0 1280 720' fill='none'%3E%3Cg opacity='0.6' filter='url(%23filter0_f_3378_68540)'%3E%3Cpath d='M956.202 509.641C1082.24 515.026 1180.65 447.577 1278.78 526.128C1308.81 550.166 1343.37 600.464 1343.37 600.464L1090.36 820.296C1090.36 820.296 1009.68 925.185 935.282 955.031C837.289 994.343 767.792 908.32 666.273 937.13C590.516 958.629 570.569 1035.11 492.034 1039.06C358.923 1045.74 269.195 782.58 269.195 782.58L-103.754 395.622L108.097 211.556C108.097 211.556 209.669 296.325 285.523 331.905C362.828 368.165 419.088 354.877 495.954 392.047C562.649 424.299 583.719 471.322 652.446 499.053C762.259 543.363 837.621 504.574 956.202 509.641Z' fill='url(%23paint0_linear_3378_68540)'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_3378_68540' x='-423.754' y='-108.444' width='2087.12' height='1467.63' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='160' result='effect1_foregroundBlur_3378_68540'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear_3378_68540' x1='1321.46' y1='408.701' x2='63.0248' y2='800.892' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2331FF9C' stop-opacity='0.7'/%3E%3Cstop offset='0.260417' stop-color='%239A3AFA'/%3E%3Cstop offset='0.494792' stop-color='%23FF72A1'/%3E%3Cstop offset='0.692708' stop-color='%23A540F0'/%3E%3Cstop offset='1' stop-color='%2337F3A2' stop-opacity='0.718'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  background-size: cover;
  background-position: 100% 100%;

  .splide__list {
    transform-style: unset;
  }
`

export const ArrowsWrapper = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.375em 0;
  position: absolute;

  > div {
    display: flex;
    position: relative;
    padding: 0 1.875em;
    justify-content: space-between;

    button:last-child {
      margin-left: auto;
    }
  }
`

export const Arrow = styled.button`
  display: flex;
  align-items: center;
  gap: 1em;
  width: fit-content;
  font-weight: 700;
  font-size: .875rem;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  height: auto;
  transform: none;
  opacity: 1;
  position: initial;
  color: #000;
  background-color: transparent;

  svg {
    fill: #000;
    height: initial;
    width: initial;
    transform: none;
  }
`
