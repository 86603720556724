const locale = {
  translations: { // Mesmo valor usado nas configurações (defaultNS)
    rotes: {
      budgeter: 'budgeter',
    },
    pages: {
      intro: 'Intro',
      description: 'Description',
      solution: 'Solution',
      enhancements: 'Enhancements',
      technology: 'Technology',
      contact: 'Contact',
    },
    home: {
      first: 'ENHANCING',
      second: 'TECHNOLOGY',
      hintMobile: 'Swipe to continue...',
      hintDesk: 'Scroll to continue...',
      hintIconMobile: 'Swipe',
      hintIconDesk: 'Scroll',
      callToAction: 'Try now'
    },
    second: {
      first: '<span>Live the</span> <span>future now</span>',
      second: '<span>Create your</span> own digital products',
      paragraph: `
        Kolabs is a <span>collaborative online ecosystem</span> that creates projects  for various areas of society.
      `,
      paragraph2: `
      We are researchers and apply innovative technologies for the <span>acceleration of the tech community</span>. Learn about autonomous programming with focus on the user.
      `,
      listItem1: `We optimize processes`,
      listItem2: `Create new technologies`,
      listItem3: `Automate routines`,
      listItem4: `Share innovations`,
    },
    third: {
      title: '_ The concept',
      paragraph: `Reality is very subjective as we <span>live inside our minds</span>. We are immersed in our everyday problems and abstraction is necessary to <span>understand what is happening around us</span>.`,
      paragraph2: `<span>Let's do a simple exercise:</span><br/> Wherever you are, forget about your problems for a moment and look around you...<br/> Note that most of the things you see are <span>focused on the function</span>.`,
      paragraph3: `Objects and systems you use every day <span>don't interact with you in a smart way</span> or offer benefits beyond the initial purpose. They are not integrated with anything and do not know anything about you to bring facilities to your life.`,
      paragraph4: `<span>All of this can be improved...</span>`,
      more: 'Learn More',
    },
    thirdMore: {
      title: '_ The manifest',
      paragraph: `As much as a professional is dedicated and loves what he does, bureaucratic tasks are exhausting by nature and this is a collective thought. The recurrent use of business platforms becomes repetitive and unattractive. Badges and scores quickly lose their stimulus function.`,
      paragraph2: `As much as you love your job, dealing with the same system becomes exhausting after a long period of time.`,
      paragraph3: `Are there ways to make you enjoy using a system?`,
      paragraph4: `Inserting some of the fundamental concepts of usability and prioritizing can help to resolve this, but there are psychological aspects that must also be taken into account, such as the basic needs of human beings and their motivations.`,
      paragraph5: `Making technology user-friendly and using it as a productive tool, making life easier for people so they can have fun while carrying out the strenuous tasks in the process of living.`,
      paragraph6: `That would be the goal of full and immersive usability and is the potential we see programming fulfill.`,
    },
    fourth: {
      title: '_ PROBLEMS?',
      paragraph: `How to <span>digitize</span>, <span>integrate</span> and <span>create</span> facilities?`,
      paragraph2: `How to get security, money, help people while we carry out our daily tasks?`,
      paragraph3: `The real world can be <span>full of technology</span> if we have the time and knowledge (granted by tech facilities) to create.`,
      paragraph4: `Taking this to a customer's interaction with a system:<br/> <strong>The interaction we seek is <span>immersion</span></strong>.`,
      more: 'Learn More',
    },
    fourthMore: {
      title: '_ User focus',
      paragraph: `You're a machine? (Don't worry! Behind that weird question there will be an explanation)`,
      paragraph2: `Sometimes you are working and sometimes you are broken, but you are not a machine. Even if you don't have behavioral swings and don't make mistakes, you couldn't compare yourself to a machine.`,
      paragraph3: `If you're human, you're not role-oriented. The human being is complex, full of feelings and highly reactive! We react to the environment, events and other human beings in ways that change our own behavior. Commands cannot be precisely predicted.`,
      paragraph4: `What we mean is that someone can give up using your platform, simply because they decided they needed some time.`,
      paragraph5: `The key is to optimize the use of the system for humans, as opposed to pure efficiency.`,
      paragraph6: `Providing immersion and customer identification with the setting is an art and not everyone has mastered it! Few companies are able to select fun, addictive, or exciting elements found in leisure sources (such as movies, games, sports, and others) to apply them to productive activities or real-world activities.`
    },
    fifth: {
      title: '_ TECHNOLOGIES',
      paragraph: 'Creating technology feels incredible, but it takes <span>passion</span>, <span>courage</span> and <span>self-confidence</span>. With the right tools, technology projects become real. Your creations come to life, being present in an inevitable future.',
      item1: {
        title: 'Web development',
        text: `Optimize your manual processes, ensure more precision in the actions performed and increase the production and service capacity of a business, allowing for the healthy expansion of the business.`,
      },
      item2: {
        title: 'Automations',
        text: `Take a manual process and make it independent of human interaction by programming machinery to carry it out. Map activities, inefficiencies and waste to eliminate them`,
      },
      item3: {
        title: 'Big Data',
        text: `The analysis of data collected by the company crossed with other market data, available in digital media. Statistical models that ensure greater predictability in decisions.`,
      },
      item4: {
        title: 'Blockchain and cloud',
        text: `Create new digital relationships in your business environment. Store and share your data in a secure, digital environment with distributed authentication and authorization.`,
      },
      more: 'Learn More',
    },
    fifthMore1: {
      title: '_ Web development',
      paragraph: `The old management process no longer generates the expected results and it is necessary to adopt technology to optimize these processes.`,
      paragraph2: `There are different management software available on the market, which provide systems prepared to assist in activities in areas such as marketing, sales, logistics and sector financial.`,
      paragraph3: `The use of these platforms optimizes the productivity of the entire team, providing the possibility of automating manual processes, ensuring more precision in the actions carried out and increasing the company's service capacity, allowing for the healthy expansion of the business.`,
      paragraph4: `The variation in prices and features is huge. It is possible to find in the market different suppliers that meet the company's needs and the stipulated budget for investment in technology.`,
    },
    fifthMore2: {
      title: '_ Automations',
      paragraph: `Automation is one of the processes most used today to facilitate the insertion of technological resources in a company, with the objective of optimizing and simplifying internal processes, in addition to reducing operating costs.`,
      paragraph2: `The main idea is to take a manual process and make it independent of human interaction, programming a system to run it.`,
      paragraph3: `That way you select something that is done manually, and from there, you use technology in favor of improvements, optimizations and make that process free from human errors.`,
      paragraph4: `The decision to automate processes brings many benefits, precisely because it saves you and your team's time.`,
      paragraph5: `The main reason for creating automations is that they promote easy access to information. One of the great challenges of corporations these days is the high competitiveness of a market that works full time (non-stop).`,
      paragraph6: `Automations arise from the mapping of all the company's activities and are applied to all repetitive processes susceptible to human error.`,
    },
    fifthMore3: {
      title: '_ Big Data',
      paragraph: `The key to making more assertive decisions. The term Big Data, translated as large amount of data, is the storage of all data that can be captured at all scales and in all possible ways.`,
      paragraph2: `The premise here is the actions that a user performs, both on a new website that he accesses, and with his habits in routine software and applications.`,
      paragraph3: `Saving your decisions, your options and your preferences leads to a much faster and optimized way to get your customer's preferences right, being able to easily display ads correctly or suggest products and services that he is really interested.`,
      paragraph4: `Not only for that, but Big Data is also for analyzing your own metrics and, of course, your company's. With this technology, you can perform comparisons and studies with the information you need, track employee progress and make data-based decisions.`,
      paragraph5: `Intelligent systems, state-of-the-art technology and good capture logic are essential to start using Big Data in your business.`,
    },
    fifthMore4: {
      title: '_ Blockchain proprietary',
      paragraph: `The new data sharing format that revolutionized the digital world is here to stay.`,
      paragraph2: `Blockchain is a term commonly related to cryptocurrencies, like Bitcoin for example, but its real functionality goes far beyond that.`,
      paragraph3: `The blockchain concept came from the need to share data, keeping your privacy at all times during the application and having a 100% secure and decentralized system, as it has a copy of all data on the computers of those who access the network.`,
      paragraph4: `Aiming to optimize technological processes and improve them, Kolabs developed its own blockchain-based algorithm focused on software development, with it we managed, following the same structural pattern of transaction organization of the blochain network, a way to segregate tasks and we assign to the most qualified resources. All this automatically and with machine learning applied to decisions`,
      paragraph5: `All development carried out by us is fully guaranteed security and interoperability between sectors and tasks.`,
    },
    sixth: {
      title: '_ HOW TO SOLVE?',
      paragraph: 'Improvements are made to make <span>life easier</span>, <span>save money</span>, or provide <span>competitive advantage</span>. When implementing apps or automating tasks, they will always fall into strategies like the ones below:',
      item1: {
        title: 'Efficiency',
        text: `The word is unattractive without being connected to a function. It comes with the function you optimize. What can you make more efficient? You can speed up processes, reduce errors and rework.`,
      },
      item2: {
        title: 'Cost reduction',
        text: `Investing in technology is spending to save. In the medium to long term, it is clear that you save a lot of resources by reducing operating costs. In addition to that, there is also the option to license your creation.`,
      },
      item3: {
        title: 'Interaction with customers',
        text: `Language processing is advancing and naturalizing the use of voice and text assistants. It's no longer awkward talking to robots. Chatbots provide quick customer responses, improving interactions.`,
      },
      item4: {
        title: 'Competitive advantage',
        text: `The best thing about investing in technology is the competitive advantage. Evolve in technology and create features that quickly enrich your product portfolio, driving the market and scaring the competition.`,
      },
      more: 'Learn More',
    },
    sixthMore1: {
      title: '_ Efficiency',
      paragraph: `The word efficiency has no appeal for those who do not connect it to a function.`,
      paragraph2: `Her attractiveness comes with the function you optimize. What can you make more efficient? Companies justify themselves by increasing efficiency, as advances in technology have made things work more efficiently, reducing errors and rework.`,
      paragraph3: `You can, for example, rely on a system for issuing invoices. In this way, avoiding manual work, making fewer mistakes and reporting more efficiently, because all typed data are automatically archived.`,
      paragraph4: `Each improvement impacts their processes in some way and, although companies must fully optimize themselves, there are priorities within each one of them. If you haven't started your business or still don't know how to start your application, the situation is the same. You need to know what pain you're going to alleviate within a business, for your customer, or what kind of problem the world has that you don't even know you have. What can you improve on the market and how will you do it? How will you do to bring more efficiency to the activities that companies in the industry perform?`,
    },
    sixthMore2: {
      title: '_ Cost reduction',
      paragraph: `At first glance, investing in technology may seem “too expensive”, but in reality there are practices you can start automating to cut costs with everyday routines, but when you look at the long term, it's clear that you'll be saving a lot more money by reducing operating costs.`,
      paragraph2: `Several call center companies, for example, use robots to answer customer calls looking for some technical support.`,
      paragraph3: `Customers seek their answers in a regular conversation and only as a last resort are they directed to speak with a human operator.`,
      paragraph4: `Create your application little by little or start slowly scanning your business, but don't forget to look to the future. Being stuck on your present earnings, aiming only for the next month, turning the balance sheet, or simply investing in other people's businesses is neglecting your own ability to create.`,
      paragraph5: `Create your application little by little or start slowly scanning your business, but don't forget to look to the future.`,
      paragraph6: `Being stuck on your present earnings, aiming only for the next month, turning the balance sheet, or simply investing in other people's businesses is neglecting your own ability to create.`,
    },
    sixthMore3: {
      title: '_ Interaction with customers',
      paragraph: `Consider the case of so-called chatbots using artificial intelligence to increasingly improve customer responses. `,
      paragraph2: `With so many advances in language processing, we have a naturalization and rise of voice and text assistants. It's not so weird anymore to talk to a robot.`,
      paragraph3: `There's also the case of social media, which also offers metrics and customer service automation solutions to help you deliver a better customer experience.`,
      paragraph4: `As machine learning technology and artificial intelligence begin to mature, the possibility of customization in technology becomes more common and accessible for small businesses as well, as demand becomes greater. The adhesion of small businesses to digital services popularizes the digital. That alone is enough for you to see how quickly supporting technologies will evolve and what to expect from these technological advances.`,
    },
    sixthMore4: {
      title: '_ Competitive advantage',
      paragraph: `Perhaps the best thing about investing in technology in companies is the possibility of gaining competitive advantage.`,
      paragraph2: `In the meantime you are constantly creating new features and enriching your portfolio.`,
      paragraph3: `Or maybe the tech race is already underway between you, so everyone expects you to follow just one streak of improvement when there are many others to explore.`,
      paragraph4: `The fact is that organizations have used technology as a competitive weapon for centuries. The sooner your company starts using new technologies and applications, the better.`,
      paragraph5: `For example, big data analytics is a challenge for most companies, while others are able to filter and manipulate data in a variety of ways. You can use customer, product, sales, and marketing data to reduce the time it takes to bring a new product to market.`
    },
    contact: {
      title: '_ CONTACT US',
      subtitle: '<span>Want to talk?</span><br/> We are available to answer your questions about our surveys. We can also help you with the <span>development of new technologies</span>!',
      send: 'Send',
      errorTitle: 'Something went wrong.',
      error: 'Your message could not be sent.',
      successTitle: 'Success!',
      success: 'Your message has been sent!',
      btnSuccess: 'Go back to Homepage',
      btnError: 'Ok',
      name: 'Name',
      namePlaceholder: 'Enter your name',
      email: 'E-mail',
      emailPlaceholder: 'Enter your e-mail',
      message: 'Message',
      messagePlaceholder: 'Enter your message',
    },
    general: {
      more: 'Learn more about...',
      prev: 'Previous',
      next: 'Next'
    }
  }
}

export default locale;
