import styled from 'styled-components/macro'
import { Scrollbars } from 'react-custom-scrollbars'
import texture from 'images/texture.webp'
import { Splide } from '@splidejs/react-splide'

export const SplideArticle = styled(Splide)`
  position: unset;

  > .splide__pagination {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: max-content;
    padding: 0;

    li {
      height: 7.33px;
      width: 100%;
      display: flex;
    }

    button {
      background: transparent;
      transform: unset;

      &.is-active {
        width: 100%;
        height: 100%;
        margin: 0;
        background: #6F6F6F;
        border-radius: 10px;
      }
    }
  }
`

export const Scroll = styled(Scrollbars)`
  > div:nth-child(3) {
    background-color: transparent;
    right: 0 !important;
    width: 7.33px !important;

    div {
      background-color: #6F6F6F !important;
      cursor: default !important;
    }
  }
`

export const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  transform: translate(-50%,-50%);
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.85) 96.33%);
  box-shadow: 0px 6px 10px rgba(102, 0, 153, 0.15);
  backdrop-filter: blur(25px);
  border: 1px solid rgba(255, 255, 255, 0.75);
  border-radius: 30px;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media (max-width: 500px) {
    top: 61.5%;
    height: 77%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
  }

  @media (min-width: 500px) {
    max-width: 90%;
    top: 50%;
  }

  @media (min-width: 896px) {
    max-width: 896px;
  }
`

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  > div:last-child {
    display: flex;
    margin-top: auto;
    align-items: flex-end;
  }

  @media (min-width: 800px) {
    padding: 0 40px 1em;
  }
`

export const Header = styled.div`
  padding: 0 20px;

  @media (min-width: 800px) {
    padding: 0 40px;
  }
`

export const LearnMore = styled.h4`
  font-size: .75rem;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 1.5px;
  color: #262626;
  margin-top: 2.9em;
`

export const Title = styled.h3`
  font-size: 2.250em;
  column-span: all;

  @media (min-width: 800px) {
    font-size: 3rem;
  }

  margin-top: .3em;
  letter-spacing: -.5px;
  margin-bottom: .5em;
  line-height: 40px;
`

export const Article = styled.article`
  padding: 1em 30px;

  @media (min-width: 800px) {
    column-count: 2;
    column-gap: 35px;
  }
`

export const Texture = styled.div`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.1) 100%);
  background: url('${texture}');
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  mix-blend-mode: overlay;
  opacity: .15;
`

export const Paragraph = styled.p`
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  margin: 0 0 1rem;
  color: #393939;
  width: 100%;
  padding: 0 20px;
  line-height: 20px;
  letter-spacing: 0.25px;

  @media (min-width: 800px) {
    font-size: 12px;
    padding: 0;
  }
`

export const BtnWrapper = styled.div`
  z-index: 2;
  position: absolute;

  @media (max-width: 800px) {
    display: flex;
    align-self: center;
    bottom: 24px;
  }

  @media (min-width: 800px) {
    top: 24px;
    right: 24px;
  }
`

export const Close = styled.button`
  background: transparent;
  display: flex;
  place-content: center;

  @media (min-width: 800px) {
    svg {
      width: 14px;
      height: 14px;
    }
  }

  padding: 1em;
  border: 0;
  cursor: pointer;
`

export const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px 0;
`

export const NavButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  gap: 1em;
  font-weight: 700;
  font-size: .875rem;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;

  svg {
    fill: #000;
  }
`

export const Page = styled.span`
  font-size: 1.25rem;
  position: absolute;
  right: 20px;
  top: 20px;
  font-weight: 600;
  letter-spacing: 0.15px;
  color: #262626;
`

export const SwipeWrapper = styled.div`
  position: absolute;
  bottom: 45px;
  right: 30px;
  opacity: .5;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  z-index: 1;

  span {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
`
