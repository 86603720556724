import { LoginModal } from 'components/login-modal'
import { Span, Title } from 'components/shared/styles'
import { LoginParticles } from 'components/login-particles'
import * as S from './login-style'

export function Login () {
  return (
    <>
      <S.Container>
        <S.Wrapper>
          <Title>Create<br /> <Span>Technology</Span></Title>

          <LoginModal />
        </S.Wrapper>

        <S.Copy>Kolabs&copy;2022 - All rights reserved</S.Copy>

        <S.LoginLight />
        <S.Overlay />
        <LoginParticles />
      </S.Container>
    </>
  )
}
