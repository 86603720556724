import { useContext, useEffect, useRef, useState, useCallback } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useTranslation } from 'react-i18next'

import { Menu } from 'components/menu'
import { HomePage } from 'components/homepage'
import { Intro } from 'components/intro'
import { Concept } from 'components/concept'
import { Problems } from 'components/problems'
import { Technologies } from 'components/technologies'
import { Solution } from 'components/solution'
import { Contact } from 'components/contact'
import { ModalContext } from 'components/contexts/modal-context'

import 'components/i18n'

import { ReactComponent as Scroll } from 'images/icons/scroll.svg'
import { ReactComponent as Swipe } from 'images/icons/swipe.svg'

import '@splidejs/splide/dist/css/splide.min.css';
import * as S from './home-style'
import { Container, Gradient } from 'components/shared/styles'
import { TSParticles } from 'components/particles'
import useMediaQuery from 'hooks/useMediaQuery'

const pages = [
  <HomePage />,
  <Intro />,
  <Concept />,
  <Problems />,
  <Solution />,
  <Technologies />,
  <Contact />,
]

export function Home () {
  const { isModalOpen } = useContext(ModalContext)
  const [isFirstPageActive, setIsFirstPageActive] = useState(true)
  const [isLastPageActive, setIsLastPageActive] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [activeBtnPagination, setActiveBtnPagination] = useState<HTMLButtonElement | null>(null)
  const [showParticles, setShowParticles] = useState(true)
  const splideRef = useRef<Splide>(null)
  const [transformValue, setTransformValue] = useState(0)
  const { i18n, t } = useTranslation()
  const isDesktop = useMediaQuery('(min-width: 799px)')

  useEffect(() => {
    setTransformValue(window.innerWidth)

    window.addEventListener('resize', () => {
      setTransformValue(window.innerWidth)
    })
  }, [setTransformValue])

  useEffect(() => {
    i18n.changeLanguage(window.location.href.includes(".com.br") && i18n.language === 'pt-BR' ? 'pt-BR' : 'en-US')
  }, [i18n])

  const showPagination = useCallback((index: number, elem: HTMLButtonElement | null) => {
    if (index > 0 && elem) {

      let page

      if (index === 1) page = t('pages.intro')
      if (index === 2) page = t('pages.description')
      if (index === 3) page = t('pages.solution')
      if (index === 4) page = t('pages.enhancements')
      if (index === 5) page = t('pages.technology')
      if (index === 6) page = t('pages.contact')

      elem.innerHTML = `<span>${page}</span>`
    }
  }, [t])

  useEffect(() => {
    showPagination(activeIndex, activeBtnPagination)
  }, [i18n.language, showPagination, activeIndex, activeBtnPagination])

  const getActivePage = (e: any) => {
    const activeIndex = e.Components.Controller.getIndex()
    const endIndex = e.Components.Controller.getEnd()

    setActiveIndex(activeIndex)
    setActiveBtnPagination(
      e.Components.Pagination.getAt(activeIndex) ?
      e.Components.Pagination.getAt(activeIndex).button : null
    )

    showPagination(
      activeIndex,
      e.Components.Pagination.getAt(activeIndex) ?
      e.Components.Pagination.getAt(activeIndex).button : null
    )

    if (activeIndex === 0) {
      setIsFirstPageActive(true)
    } else {
      setTimeout(() => {
        setIsFirstPageActive(false)
      }, 400)
    }

    setShowParticles(activeIndex === 0 ? true : false)
    setIsLastPageActive(activeIndex === endIndex ? true : false)
  }

  return (
    <>
      <Container>
        <Menu />

        <S.Content
          ref={splideRef}
          onMove={getActivePage}
          onPaginationUpdated={getActivePage}
          onPaginationMounted={getActivePage}
          options={{
            width: '100vw',
            height: '100vh',
            arrows: false,
            perMove: 1,
            perPage: 1,
            flickPower: 500,
            flickMaxPages: 1,
            easing: 'cubic-bezier(1, 0, 0, 1)',
            speed: 800,
            pagination: !isModalOpen && !isFirstPageActive ? true : false,
            waitForTransition: true,
            drag: !isModalOpen && !isDesktop ? true : false,
            wheel: true,
            keyboard: true,
            mediaQuery: 'min',
            updateOnMove: true,
            breakpoints: {
              0: {
                pagination: !isModalOpen && !isFirstPageActive ? true : false,
              },
              800: {
                drag: false,
                pagination: !isModalOpen && !isFirstPageActive ? true : false,
              }
            }
          }}
        >
          {pages.map((page, index) => {
            return (
              <SplideSlide key={index}>
                <S.Wrapper>
                  {page}
                </S.Wrapper>
              </SplideSlide>
            )
          })}
        </S.Content>

        {/* {!isFirstPageActive && isDesktop && (
          <S.CTAButton to={i18n.language === 'en-US' ? '/budgeter' : 'calculadora'}>{t('home.callToAction')}</S.CTAButton>
        )} */}

        {!isLastPageActive ? (
          <S.ScrollIconWrapper
            style={{
              zIndex: isModalOpen ? -1 : 0,
              bottom: !isFirstPageActive && !isDesktop ? '4em' : '30px',
            }}>
            {isDesktop ? (
              <>
                <Scroll />

                <span>{t('home.hintIconDesk')}</span>
              </>
            ) : (
              <>
                <Swipe />

                <span>{t('home.hintIconMobile')}</span>
              </>
            )}
          </S.ScrollIconWrapper>
          ) : null
        }

        <Gradient />

        {activeIndex < 2 && (
          <TSParticles translateX={showParticles ? '0' : `-${transformValue}`} />
        )}
      </Container>
    </>
  )
}
