import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  height: max-content;
  gap: 1em;
  flex-direction: column;
  border-radius: 10px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.85) 96.33%);
  box-shadow: 0px 4px 2px rgba(0, 0, 0, .15);
  padding: .5em 1em 1em;
  box-shadow: 0px 4px 2px 0px #00000026, 0px -5px 6px 0px #66009926 inset;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    gap: .5em;
  }

  @media (min-width: 500px) {
    padding: .5em 1.25em 1.25em;
  }

  @media (min-width: 800px) and (min-height: 620px) {
    padding: .5em 1.625em 1.625em;
  }

  position: relative;
`

export const Overlay = styled.div`
  min-height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: -1;
  mix-blend-mode: normal;
  backdrop-filter: blur(25px);
  position: absolute;
`

export const WrapperImage = styled.div`
  display: flex;
  align-items: end;
  gap: 5px;

  @media (min-width: 800px) {
    flex-direction: column-reverse;
    align-items: start;

    img {
      height: 100%;
      margin: 0 auto;
    }
  }

  @media (max-width: 800px) {
    img {
      height: 64px;
      aspect-ratio: 1/1;
      margin: 0 auto;
    }
  }

  img {
    margin-left: auto;
    align-self: start;
  }
`

export const Title = styled.h2`
  font-weight: 700;
  font-size: 1.5rem;
  width: 100%;
  line-height: 24px;
  color: #161616;
`

export const TextDescriptive = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: .75rem;
  width: 100%;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #525252;
`
