import { Routes } from 'components/routes'
import { BrowserRouter as Router } from 'react-router-dom'

export function App () {
  return (
    <>
      <Router>
        <Routes />
      </Router>
    </>
  )
}
