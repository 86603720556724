import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Title,
  Span,
  DecagonSVG,
  DecagonBlur,
} from 'components/shared/styles'

import * as S from './homepage-style'

export function HomePage () {
  const { t } = useTranslation()
  const [minWidth, setMinWidth] = useState(false)

  useEffect(() => {
    setMinWidth(window.innerWidth > 799 ? true : false)

    window.addEventListener('resize', () => {
      setMinWidth(window.innerWidth > 799 ? true : false)
    })
  }, [minWidth])

  return (
    <>
      <S.Wrapper>
        <Title>
          {t('home.first')}<br />

          <Span>{t('home.second')}</Span>
        </Title>

        <S.DecagonWrapper>
          <DecagonSVG />

          <DecagonBlur />
        </S.DecagonWrapper>

        <S.Hint>
          <span>{minWidth ? t('home.hintDesk') : t('home.hintMobile')}</span>
        </S.Hint>
      </S.Wrapper>
    </>
  )
}
