import styled from 'styled-components/macro'
import { ReactComponent as MenuDown } from 'images/icons/menu-dropdown.svg'
import { ReactComponent as BrasilFlag} from 'images/icons/brazil-flag.svg'
import { ReactComponent as UnitedStatesFlag} from 'images/icons/united-states-flag.svg'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const FlagContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  z-index: 1;
`

export const MenuDownIcon = styled(MenuDown)`
  width: .7em;
  height: .7em;
`

export const BrasilFlagIcon = styled(BrasilFlag)`
  width: 1.2em;
  height: 1.2em;
`

export const UnitedStatesFlagIcon = styled(UnitedStatesFlag)`
  width: 1.14em;
  height: 1.14em;
`

export const DropdownContainer = styled.div`
  position: absolute;
  top: 30px;
  z-index: 1;
  right: 0;
  padding: 1em;
  border-radius: 10px;
  background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.85) 96.33%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(25px);
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.45);

  button:first-child {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 7px;
  }

  button:last-child {
    padding-top: 7px;
  }

  button {
    background: transparent;
    padding: 0;
    border: 0;
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
    min-width: max-content;
    align-items: center;
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
`;
