import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

const I18n = () => {
  const { i18n } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState()

  function handleChangeLanguage(language) {
    i18n.changeLanguage(language)
    setVisible(!visible)
  }

  useEffect(() => {
    setSelectedLanguage(i18n.language)
  }, [i18n.language])

  return (
    <>
      <S.Container>
        <S.FlagContainer onClick={() => setVisible(!visible)}>
          {selectedLanguage === 'pt-BR' ? <S.BrasilFlagIcon /> : <S.UnitedStatesFlagIcon />}

          <S.MenuDownIcon />
        </S.FlagContainer>

        {visible && (
          <S.DropdownContainer>
            <button
              type="button"
              onClick={() => handleChangeLanguage('pt-BR')}
            >
              <S.BrasilFlagIcon />
            </button>

            <button
              type="button"
              onClick={() => handleChangeLanguage('en-US')}
            >
              <S.UnitedStatesFlagIcon />
            </button>
          </S.DropdownContainer>
        )}
      </S.Container>

      {visible && <S.Wrapper onClick={() => setVisible(false)} />}
    </>
  )
}

export default I18n
