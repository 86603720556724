import { ModalContext } from 'components/contexts/modal-context'
import { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import I18n from '../i18n/I18n'
import { useTranslation } from 'react-i18next'

import * as S from './menu-style'
import useMediaQuery from 'hooks/useMediaQuery'

export function Menu () {
  const { isModalOpen } = useContext(ModalContext)
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const maxWidth = useMediaQuery('(max-width: 799px)')

  return (
    <S.MenuContainer
      style={{ zIndex: isModalOpen ? 0 : 1}}
    >
      <Link to="/">
        <S.KolabsLogo />
      </Link>

      <S.Menu>
        {maxWidth && (
          <div>
            <S.CTAButton to={i18n.language === 'en-US' ? '/budgeter' : 'calculadora'}>{t('home.callToAction')}</S.CTAButton>

            <S.Line />
          </div>
        )}

        <S.MenuItem onClick={() => navigate('/login')}>LOGIN</S.MenuItem>

        <S.MenuItem>
          <S.InstagramIcon />
        </S.MenuItem>

        <I18n />
      </S.Menu>
    </S.MenuContainer>
  )
}
