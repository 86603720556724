import {
  useState,
  createContext,
  ReactNode,
  SetStateAction,
  Dispatch,
} from 'react'

type ModalContextData = {
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  modalId: number
  budgeterModalId: string
  setModalId: Dispatch<SetStateAction<number>>
  setBudgeterModalId: Dispatch<SetStateAction<string>>
  isBudgeterModalOpen: boolean
  setIsBudgeterModalOpen: Dispatch<SetStateAction<boolean>>
}

type ModalProviderProps = {
  children: ReactNode
}

export const ModalContext = createContext({} as ModalContextData)

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isBudgeterModalOpen, setIsBudgeterModalOpen] = useState(false)
  const [modalId, setModalId] = useState(0)
  const [budgeterModalId, setBudgeterModalId] = useState('')

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,
        modalId,
        setModalId,
        isBudgeterModalOpen,
        setIsBudgeterModalOpen,
        budgeterModalId,
        setBudgeterModalId
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
