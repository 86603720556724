import { Routes as Switch, Route } from 'react-router-dom'
import { Home } from 'pages/home'
import { Login } from 'pages/login'
import { Budgeter } from 'pages/budgeter'

export function Routes () {
  return (
    <Switch>
      <Route path='/' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/calculadora' element={<Budgeter />} />
      <Route path='/budgeter' element={<Budgeter />} />
    </Switch>
  )
}
