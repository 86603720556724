import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Text,
  TitleStart,
  TopGradient,
} from 'components/shared/styles'
import { ReactComponent as CheckIcon } from 'images/icons/check-circle.svg'
import { ReactComponent as ErrorIcon } from 'images/icons/error-circle.svg'

import * as S from './contact-style'

type responseType = {
  title: string
  buttonText: string
  message: string
  type: string
}

export function Contact () {
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [requestResult, setRequestResult] = useState<null | responseType>(null)
  const [isAlertOpen, setIsAlertOpen] = useState(false)

  useEffect(() => {
    let alertTimer = setTimeout(() => {
      setIsAlertOpen(false)
    }, 4000)

    return () => clearTimeout(alertTimer)
  }, [isAlertOpen])

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault()

    try {
      await fetch(`https://formsubmit.co/ajax/${process.env.REACT_APP_CONTACT_EMAIL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          name,
          email,
          message
        })
      })

      setName('')
      setEmail('')
      setMessage('')
      setIsAlertOpen(true)
      setRequestResult({
        title: t("contact.successTitle"),
        buttonText: t("contact.btnSuccess"),
        message: t("contact.success"),
        type: 'success',
      })
    } catch (err: unknown) {
      setIsAlertOpen(true)
      setRequestResult({
        title: t("contact.errorTitle"),
        buttonText: t("contact.btnError"),
        message: t("contact.error"),
        type: 'error',
      })
    }
  }

  const handleMsgClick = () => {
    if (requestResult?.type === 'error') {
      setIsAlertOpen(false)
    } else {
      setIsAlertOpen(false)
      window.location.reload()
    }
  }

  return (
    <>
      <S.Container>
        <S.Alert
          style={{
            opacity: isAlertOpen ? '1' : '0',
            visibility: isAlertOpen ? 'visible' : 'hidden',
          }}
        >
          {requestResult?.type === 'error' && <ErrorIcon />}
          {requestResult?.type === 'success' && <CheckIcon />}

          <S.AlertTitle>{requestResult?.title}</S.AlertTitle>

          <p>{requestResult?.message}</p>

          <S.AlertBtn onClick={handleMsgClick}>{requestResult?.buttonText}</S.AlertBtn>
        </S.Alert>

        <Text>
          <TitleStart>{t("contact.title")}</TitleStart>

          <S.Paragraph dangerouslySetInnerHTML={{__html: t("contact.subtitle")}} />
        </Text>

        <S.Form onSubmit={handleFormSubmit}>
          <div>
            <S.FieldWrapper>
              <S.ContactFormLabel>{t("contact.name")}</S.ContactFormLabel>

              <S.Input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                name="name"
                required
                placeholder={t("contact.namePlaceholder")}
              />
            </S.FieldWrapper>

            <S.FieldWrapper>
              <S.ContactFormLabel>{t("contact.email")}</S.ContactFormLabel>

              <S.Input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                name="email"
                required
                placeholder={t("contact.emailPlaceholder")}
              />
            </S.FieldWrapper>
          </div>

          <S.FieldWrapper>
            <S.ContactFormLabel>{t("contact.message")}</S.ContactFormLabel>

            <S.StyledArea
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              required
              placeholder={t("contact.messagePlaceholder")}
              name="message"
            />
          </S.FieldWrapper>

          <input type="text" name="_honey" style={{display:'none'}} />
          <input type="hidden" name="_captcha" value="false" />

          <S.SendButton type="submit">
            <div />

            {t("contact.send")}
          </S.SendButton>
        </S.Form>
      </S.Container>

      <TopGradient />
    </>
  )
}
