import Particles from 'react-tsparticles'
import styled from 'styled-components/macro'

const ParticlesWrapper = styled(Particles)`
  height: 60%;
  width: 100%;

  canvas {
    transform-style: preserve-3d;
    transition: transform 800ms cubic-bezier(1, 0, 0, 1) 0s;
  }

  @media (min-width: 800px) {
    width: 60%;
    height: 100%;
  }

  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -4;
  filter: blur(1px);
`

type ParticlesProps = {
  translateX: string
}

export function TSParticles ({ translateX }: ParticlesProps) {
  return (
    <>
      <ParticlesWrapper
        style={{ transform: `translateX(${translateX}px)`}}
        id='tsparticles'
        options={{
          fullScreen: {
            enable: false,
          },
          fpsLimit: 60,
          interactivity: {
            events: {
              onHover: {
                enable: true,
                mode: 'grab',
                parallax: {
                  enable: true,
                  force: 60,
                  smooth: 10
                },
              },
              resize: true,
            },
            modes: {
              grab: {
                distance: 100,
                links: {
                  opacity: 0.5
                }
              }
            },
          },
          particles: {
            color: {
              value: 'rgba(215,183,246,1)',
            },
            links: {
              color: 'rgba(215,183,246,1)',
              distance: 150,
              enable: true,
              width: 4,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: 'none',
              enable: true,
              outMode: 'bounce',
              random: false,
              speed: 1.5,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 100,
              },
              value: 10,
            },
            opacity: {
              value: 1,
            },
            shape: {
              type: 'circle',
            },
            size: {
              random: true,
              value: 9,
            },
          },
          detectRetina: true,
        }}
      />
    </>
  )
}
