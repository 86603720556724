import styled from 'styled-components/macro'
import { BaseContainer } from 'components/shared/styles'

type GridType = {
  length: number
}

export const Container = styled(BaseContainer)`
  padding: 0;
  padding-top: 5em;

  @media (max-width: 800px) {
    padding-top: 6em;
  }

  justify-content: start;
`

export const PageTitle = styled.h1`
  font-size: 1.5rem;
  padding: 0 24px;

  @media (min-width: 800px) {
    padding: 0 4vw;
  }

  @media (min-width: 900px) {
    font-size: 2.250rem;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const Grid = styled.div<GridType>`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(auto-fit, 118px);
  padding: 2em 2.188em 0;
  justify-content: center;

  @media (min-width: 1040px) {
    width: 100%;
    grid-template-columns: repeat(auto-fit, 160px);
  }

  @media (min-width: 900px) {
    column-gap: 2.625em;
  }

  @media (max-width: 768px) {
    width: ${({ length }) => length > 4 && length < 7 ? '500px' : length > 6 && length < 8 ? '600px' : length > 8 ? '800px' : '100%'};
    justify-content: ${({ length }) => length > 4 ? 'space-between' : 'center'};
  }

  column-gap: ${({ length }) => length > 4 ? '1.625em' : '2.625em'};
  row-gap: 1.625em;
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
`
