import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Card } from 'components/card'
import styled from 'styled-components/macro'

const SlideWrapper = styled(Splide)`
  position: relative;

  .splide__list {
    background: transparent !important;
  }

  .splide__track {
    padding-bottom: 1em;
  }

  .splide__arrows {
    position: absolute;
    bottom: 0;
    display: flex;
    margin-bottom: -60px;
    left: 1.5em;
    gap: 18px;

    button {
      border: none;
      width: 36px;
      height: 36px;
    }

    svg {
      width: 12px;
    }
  }

  .splide__arrow {
    background: linear-gradient(180deg,rgba(69,16,138,.5) 0%,rgba(164,94,255,.5) 94.59%);

    svg path {
      fill: #fff;
    }

    box-shadow: 0px 5px 10px 0px #00000040, 0px -4px 6px 0px #FFFFFF80;
    border: 1px solid rgba(255, 255, 255, .45);
  }

  .splide__arrow--prev {
    position: initial;
  }

  .splide__arrow--next {
    position: initial;
  }
`

type InnerSlideProps = {
  items: {
    title: string
    text: string
    buttonText: string
    image: string
    id: number
  }[]
}

export function InnerSlide ({ items }: InnerSlideProps) {
  return (
    <SlideWrapper
      options={{
        type: 'slide',
        width: '100%',
        focus: 'center',
        perMove: 1,
        perPage: 1.12,
        speed: 800,
        padding: '24px',
        gap: '1em',
        pagination: false,
        waitForTransition: true,
        mediaQuery: 'min',
        drag: false,
        keyboard: false,
        breakpoints: {
          360: {
            perPage: 1.12,
          },
          400: {
            perPage: 1.5,
          },
          500: {
            perPage: 1.8,
          },
          600: {
            perPage: 2.2,
          },
          667: {
            perPage: 2.5,
          },
          900: {
            perPage: 3.2,
            arrows: true,
          },
          1044: {
            perPage: items.length,
            arrows: false,
          }
        }
      }}
    >
      {items.map((item, index) => {
        return (
          <SplideSlide key={index}>
            <Card {...item} />
          </SplideSlide>
        )
      })}
    </SlideWrapper>
  )
}
