import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'components/modal'
import { ModalContext } from 'components/contexts/modal-context'
import {
  Button,
  OverlayModal,
  ParagraphStart,
  TitleStart,
  TopGradient,
} from 'components/shared/styles'
import Locky from 'react-locky'

import * as S from './concept-style'

export function Concept () {
  const { t } = useTranslation()
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext)

  const content = {
    title: t('thirdMore.title'),
    paragraphs: [
      t('thirdMore.paragraph'),
      t('thirdMore.paragraph2'),
      t('thirdMore.paragraph3'),
      t('thirdMore.paragraph4'),
      t('thirdMore.paragraph5'),
      t('thirdMore.paragraph6'),
    ]
  }

  return (
    <>
      {isModalOpen && <Modal content={content} />}
      {isModalOpen && <OverlayModal  onClick={() => setIsModalOpen(prev => !prev)} />}
      {isModalOpen && <Locky enabled={isModalOpen} noDefault events={{wheel: true, touchstart: false}}>{null}</Locky>}

      <S.Container>
        <S.Grid>
          <div>
            <TitleStart>{t('third.title')}</TitleStart>

            <ParagraphStart dangerouslySetInnerHTML={{__html: t('third.paragraph')}} />
          </div>

          <div>
            <ParagraphStart dangerouslySetInnerHTML={{__html: t('third.paragraph2')}} />

            <ParagraphStart dangerouslySetInnerHTML={{__html: t('third.paragraph3')}} />

            <ParagraphStart dangerouslySetInnerHTML={{__html: t('third.paragraph4')}} />

            <Button onClick={() => setIsModalOpen(true)}>
              <div />

              {t('third.more')}
            </Button>
          </div>
        </S.Grid>
      </S.Container>

      <TopGradient />
      <S.Illustration />
    </>
  )
}
