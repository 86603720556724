import styled from 'styled-components/macro'
import man from 'images/man-bg.webp'
import { BaseContainer } from 'components/shared/styles'

export const Container = styled(BaseContainer)`
  padding: 8em 24px 0;
  justify-content: flex-start;
  max-width: 1280px;
  margin: 0 auto;

  @media (max-height: 450px) and (min-width: 800px) {
    padding-top: 5em;
    justify-content: start;
  }

  @media (min-height: 450px) and (min-width: 800px) {
    flex-direction: row;
    padding: 0 24px 0;
    align-items: center;
  }
`

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  width: 100%;

  h1 {
    margin-bottom: 26px;
    letter-spacing: .44px;
  }

  p {
    font-size: .75rem;
    margin-top: 1em;
    margin-bottom: 0;

    @media (min-width: 800px) {
      line-height: 24px;
      letter-spacing: .44px;
    }
  }

  > div:first-child {
    p {
      span {
        background: -webkit-linear-gradient(309.83deg, #00A8A8 6.1%, #6889FF 13.98%, #CC33FF 65.12%, #FF00E5 104.11%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
      }
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: column;

    p:nth-child(1) {
      > span:nth-child(1) {
        text-transform: uppercase;
        font-family: 'Rajdhani', sans-serif;
        font-weight: 700;
        font-size: 1.125rem;
        display: inline-block;
      }

      > span:last-child {
        background: -webkit-linear-gradient(309.83deg, #00A8A8 6.1%, #6889FF 13.98%, #CC33FF 65.12%, #FF00E5 104.11%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
      }
    }

    p:nth-child(2) {
      span {
        background: -webkit-linear-gradient(309.83deg, #00A8A8 6.1%, #6889FF 13.98%, #CC33FF 65.12%, #FF00E5 104.11%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
      }
    }

    p:nth-child(3) {
      span {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: .875rem;
        display: inline-block;
      }
    }

    button {
      margin-top: 2.438em;
    }
  }

  @media (min-width: 600px) {
    p {
      max-width: 600px;
      font-size: .875rem;
    }
  }

  @media (min-width: 800px) {
    p {
      font-size: 1rem;
    }
  }

  @media (min-height: 450px) and (min-width: 900px) {
    > div {
      margin-top: 5%;
    }

    > div:first-child {
      margin-top: 7%;
    }
  }

  @media (min-width: 900px) {
    flex-direction: row;
    max-height: 30em;
    place-content: space-between;

    p {
      max-width: 370px;
    }

    > div:first-child {
      h1 {
        margin-bottom: 40px;
        white-space: nowrap;
      }
    }

    > div:last-child {
      p:nth-child(1) {
        span {
          margin-bottom: 1em;
        }
      }

      p:nth-child(3) {
        span {
          font-size: 1.125rem;
        }
      }

      button {
        margin: 2.438em 2em 0 auto;
      }
    }
  }
`

export const Illustration = styled.div`
  background-image: url(${man});
  background-size: 37vh;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  opacity: .5;
  max-width: 45em;

  @media (min-width: 899px) {
    background-position: 50% 100%;
    background-size: 45vh;
    opacity: 1;
  }

  @media (min-width: 1260px) {
    background-size: 55vh;
  }

  @media (min-width: 1280px) {
    background-size: 64vh;
  }

  @media (min-height: 730px) {
    background-size: 490px;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -3;
  height: 100%;
  width: 100%;
`
