import Particles from 'react-tsparticles'
import styled from 'styled-components/macro'

const ParticlesWrapper = styled(Particles)`
  height: 60%;
  width: 70%;

  @media (min-width: 800px) {
    width: 70%;
    height: 100%;
  }

  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
`

export function LoginParticles () {
  return (
    <>
      <ParticlesWrapper
      id='tsparticles'
      options={{
        fullScreen: {
          enable: false,
        },
        fpsLimit: 60,
        interactivity: {
          events: {
            onHover: {
              enable: true,
              mode: 'grab',
              parallax: {
                enable: true, force: 60, smooth: 10
              },
            },
            resize: true,
          },
          modes: {
            grab: {
              links: {
                opacity: 0.1
              }
            }
          },
        },
        particles: {
          color: {
            value: '#fff',
          },
          links: {
            color: '#fff',
            distance: 150,
            enable: true,
            opacity: 0.8,
            width: 4,
          },
          collisions: {
            enable: false,
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 1.5,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 100,
          },
          opacity: {
            value: 0.8,
          },
          shape: {
            type: 'circle',
          },
          size: {
            random: true,
            value: 6,
          },
        },
        detectRetina: true,
      }}
    />
    </>
  )
}
