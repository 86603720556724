import * as S from './budgeter-modal-style'
import { ReactComponent as RemoveIcon } from 'images/icons/remove.svg'
import { ModalContext } from 'components/contexts/modal-context'
import { MouseEvent, useContext } from 'react'

export function BudgeterModal () {
  const { setIsBudgeterModalOpen } = useContext(ModalContext)

  const handleRemoveClick = (e: MouseEvent) => {
    e.stopPropagation()
    setIsBudgeterModalOpen(false)
  }

  return (
    <S.Container>
      <S.RemoveBtn onClick={handleRemoveClick}>
        <RemoveIcon />
      </S.RemoveBtn>
    </S.Container>
  )
}
