import styled from 'styled-components/macro'
import { BaseContainer } from 'components/shared/styles'

export const Wrapper = styled(BaseContainer)`
  padding: 0;

  @media (max-height: 580px) and (min-width: 800px) {
    padding-top: 3em;
    justify-content: start;
  }

  @media (max-height: 600px) and (max-width: 800px) {
    padding-top: 7em;
    justify-content: start;
  }
`

export const DecagonWrapper = styled.div`
  margin: 0 auto;
  z-index: 1;
  margin-top: -30px;
  aspect-ratio: 1/1.062;
  position: relative;
  width: clamp(328px, 60vw, 399px);
  display: flex;
  justify-content: center;

  @media (min-width: 400px) {
  margin-top: -11vw;
  }

  @media (min-width: 800px) {
  margin-top: -90px;
  }
`

export const Hint = styled.div`
  position: absolute;
  z-index: 2;
  left: 0;
  width: 100%;
  text-align: center;
  bottom: 2em;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 0.25px;
`
