import styled from 'styled-components/macro'
import texture from 'images/texture.webp'
import { BaseContainer, Gradient } from 'components/shared/styles'

export const Container = styled(BaseContainer)`
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1280' height='720' viewBox='0 0 1280 720' fill='none'%3E%3Cg opacity='0.5' filter='url(%23filter0_f_1057_556)'%3E%3Cpath d='M525.448 121.302C416.529 36.358 286.375 34.2996 248.371 -98.5369C236.741 -139.187 237.606 -206.269 237.606 -206.269L601.435 -242.623C601.435 -242.623 739.343 -285.425 824.427 -264.905C936.492 -237.877 944.276 -116.54 1052.87 -77.8512C1133.91 -48.9803 1199.83 -104.904 1272.29 -58.5216C1395.12 20.093 1309.43 313.18 1309.43 313.18L1398.21 897.337L1093.57 927.777C1093.57 927.777 1056.42 787.177 1011.22 707.045C965.156 625.382 906.659 601.539 861.559 519.339C822.427 448.015 833.249 392.443 789.461 323.882C719.496 214.335 627.926 201.224 525.448 121.302Z' fill='url(%23paint0_linear_1057_556)'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f_1057_556' x='-82.4082' y='-590.473' width='1800.62' height='1838.25' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='160' result='effect1_foregroundBlur_1057_556'/%3E%3C/filter%3E%3ClinearGradient id='paint0_linear_1057_556' x1='136.43' y1='-20.3355' x2='1507.21' y2='419.922' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2331FF9C' stop-opacity='0.7'/%3E%3Cstop offset='0.736888' stop-color='%239A3AFA'/%3E%3Cstop offset='1' stop-color='%23FF72A1'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  background-position: 50% 0%;
  background-size: cover;
  min-height: 100vh;
  place-content: center;
`

export const LoginLight = styled(Gradient)`
  z-index: -2;
  background: linear-gradient(260deg, rgb(99.608% 99.608% 99.608% / 0) 0%, rgb(99.612% 99.612% 99.612% / 0.009607359798384785) 6.25%, rgb(99.623% 99.623% 99.623% / 0.03806023374435663) 12.5%, rgb(99.641% 99.641% 99.641% / 0.08426519384872738) 18.75%, rgb(99.665% 99.665% 99.665% / 0.1464466094067262) 25%, rgb(99.695% 99.695% 99.695% / 0.22221488349019886) 31.25%, rgb(99.729% 99.729% 99.729% / 0.3086582838174551) 37.5%, rgb(99.766% 99.766% 99.766% / 0.40245483899193585) 43.75%, rgb(99.804% 99.804% 99.804% / 0.49999999999999994) 50%, rgb(99.842% 99.842% 99.842% / 0.5975451610080641) 56.25%, rgb(99.879% 99.879% 99.879% / 0.6913417161825448) 62.5%, rgb(99.913% 99.913% 99.913% / 0.777785116509801) 68.75%, rgb(99.943% 99.943% 99.943% / 0.8535533905932737) 75%, rgb(99.967% 99.967% 99.967% / 0.9157348061512727) 81.25%, rgb(99.985% 99.985% 99.985% / 0.9619397662556434) 87.5%, rgb(99.996% 99.996% 99.996% / 0.9903926402016152) 93.75%, rgb(100% 100% 100%) 100% );
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  z-index: 1;
`

export const Overlay = styled.div`
  min-height: 100%;
  width: 100%;
  position: fixed;
  background-image: url('${texture}');
  background-size: cover;
  z-index: -3;
  top: 0;
  left: 0;
  opacity: .2;
`

export const Copy = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, .25);
  display: flex;
  position: fixed;
  bottom: 3em;
  align-self: center;
  padding: 0 1em;
  width: max-content;

  @media (min-width: 800px) {
    bottom: 1em;
    align-self: flex-start;
  }
`
