import { useContext } from 'react';
import { ModalContext } from 'components/contexts/modal-context';
import { Modal } from 'components/modal';
import {
  Button,
  OverlayModal,
  Paragraph,
  Title,
  TopGradient,
} from 'components/shared/styles';
import { useTranslation } from 'react-i18next';

import * as S from './problems-style'

export function Problems () {
  const { t } = useTranslation()
  const { isModalOpen, setIsModalOpen } = useContext(ModalContext)

  const content = {
    title: t('fourthMore.title'),
    paragraphs: [
      t('fourthMore.paragraph'),
      t('fourthMore.paragraph2'),
      t('fourthMore.paragraph3'),
      t('fourthMore.paragraph4'),
      t('fourthMore.paragraph5'),
      t('fourthMore.paragraph6')
    ]
  }

  return (
    <>
      {isModalOpen && <Modal content={content} />}
      {isModalOpen && <OverlayModal onClick={() => setIsModalOpen(prev => !prev)} />}

      <S.Container>
        <S.Flex>
          <Title>{t('fourth.title')}</Title>

          <div>
            <Paragraph  dangerouslySetInnerHTML={{__html: t('fourth.paragraph')}} />

            <Paragraph dangerouslySetInnerHTML={{__html: t('fourth.paragraph2')}} />

            <Paragraph dangerouslySetInnerHTML={{__html: t('fourth.paragraph3')}} />

            <Paragraph dangerouslySetInnerHTML={{__html: t('fourth.paragraph4')}} />
          </div>

          <Button onClick={() => setIsModalOpen(true)}>
            <div />

            {t('fourth.more')}
          </Button>
        </S.Flex>

        <TopGradient />
        <S.Illustration />
      </S.Container>
    </>
  )
}
