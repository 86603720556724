import styled from 'styled-components/macro'
import { ReactComponent as HelpSVG } from 'images/icons/help.svg'

type CardType = {
  selected: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;

`

export const ModalWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  left: 105%;
  cursor: default;
`

export const Card = styled.div<CardType>`
  width: 100%;
  aspect-ratio: 1/1;
  border: ${({ selected }) => selected ? 'none' : '1px solid rgba(255, 255, 255, .75)'};
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;

  --box-border--border: linear-gradient(109.83deg, #00A8A8 6.1%, #6889FF 33.98%, #CC33FF 65.12%, #FF00E5 104.11%);
  --box--border__top-left: url("data:image/svg+xml,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='1' y='1' width='18' height='18' rx='20' ry='20' stroke='%23000' stroke-width='3' /></svg>");
  --box--border__top: url("data:image/svg+xml,<svg preserveAspectRatio='none' width='100' height='10' viewBox='0 0 100 10' fill='none' xmlns='http://www.w3.org/2000/svg'><line x1='-1' y1='1' x2='101' y2='1' stroke='%23000' stroke-width='3'/></svg>");
  --box--border__top-right: url("data:image/svg+xml,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='-9' y='1' width='18' height='18' rx='20' ry='20' stroke='black' stroke-width='3' /></svg>");
  --box--border__left: url("data:image/svg+xml,<svg preserveAspectRatio='none' width='10' height='100' viewBox='0 0 10 100' fill='none' xmlns='http://www.w3.org/2000/svg'><line x1='1' y1='-1' x2='1' y2='101' stroke='%23000' stroke-width='3'/></svg>");
  --box--border__right: url("data:image/svg+xml,<svg preserveAspectRatio='none' width='10' height='100' viewBox='0 0 10 100' fill='none' xmlns='http://www.w3.org/2000/svg'><line x1='9' y1='-1' x2='9' y2='101' stroke='%23000' stroke-width='3'/></svg>");
  --box--border__bottom-left: url("data:image/svg+xml,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='1' y='-9' width='18' height='18' rx='20' ry='20' stroke='%23000' stroke-width='3' /></svg>");
  --box--border__bottom: url("data:image/svg+xml,<svg preserveAspectRatio='none' width='100' height='10' viewBox='0 0 100 10' fill='none' xmlns='http://www.w3.org/2000/svg'><line x1='-1' y1='9' x2='101' y2='9' stroke='%23000' stroke-width='3'/></svg>");
  --box--border__bottom-right: url("data:image/svg+xml,<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'><rect x='-9' y='-9' width='18' height='18' rx='20' ry='20' stroke='black' stroke-width='3' /></svg>");

  ${({ selected }) => selected && `
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      aspect-ratio: 1/1;
      background: var(--box-border--border);
      mask:
        var(--box--border__top-left) 0 0 / .625rem .625rem,
        var(--box--border__top) .625rem 0 / calc(100% - 1.25rem) .625rem,
        var(--box--border__top-right) 100% 0 / .625rem .625rem,
        var(--box--border__left) 0 .625rem / .625rem calc(100% - 1.25rem),
        var(--box--border__right) 100% .625rem / .625rem calc(100% - 1.25rem),
        var(--box--border__bottom-left) 0 100% / .625rem .625rem,
        var(--box--border__bottom) .625rem 100% / calc(100% - 1.25rem) .625rem,
        var(--box--border__bottom-right) 100% 100% / .625rem .625rem;
      mask-repeat: no-repeat;
    }
  `}
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
`

export const HelpIcon = styled(HelpSVG)`
  display: flex;
`

export const Caption = styled.h2`
  font-family: 'Roboto', sans-serif;
  margin-top: 7px;
  color: #000;
  font-weight: 600;
  overflow-wrap: anywhere;
  font-size: .75rem;
  line-height: 1.6;
  letter-spacing: .4px;
`
