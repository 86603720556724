import { ParagraphEnd } from 'components/shared/styles'
import styled from 'styled-components/macro'

export const Paragraph = styled(ParagraphEnd)`
  margin-bottom: 0;
  margin-top: 1.5em;

  @media (min-width: 800px) {
    letter-spacing: .44px;
    line-height: 24px;
  }

  @media (min-width: 1120px) {
    margin-top: 0;
    max-width: 530px;
  }

  span {
    background: -webkit-linear-gradient(309.83deg, #00A8A8 6.1%, #6889FF 13.98%, #CC33FF 65.12%, #FF00E5 104.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }
`
