import styled from 'styled-components/macro'

export const Container = styled.div`
  cursor: default;
  width: 281px;
  height: 229px;
  aspect-ratio: 1/1;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.1) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(35px);
  border-radius: 20px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, .75);
`

export const RemoveBtn = styled.button`
  display: flex;
  place-content: center;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  right: 0;
  top: -30px;
  position: absolute;
  margin-bottom: 5px;
  margin-right: 2px;
`
