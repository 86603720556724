import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import efficiency from 'images/icons/efficiency.svg'
import costs from 'images/icons/costs.svg'
import support from 'images/icons/support.svg'
import swords from 'images/icons/swords.svg'

import { InnerSlide } from 'components/inner-slide'
import { Modal } from 'components/modal'
import { ModalContext } from 'components/contexts/modal-context'
import {
  ContainerInnerSlide,
  OverlayModal,
  TitleStart,
  Text,
  TopGradient
} from 'components/shared/styles'
import * as S from './solution-style'

export function Solution () {
  const { t } = useTranslation()
  const { isModalOpen, setIsModalOpen, modalId } = useContext(ModalContext)

  const content = [
    {
      title: t('sixthMore1.title'),
      paragraphs: [
        t('sixthMore1.paragraph'),
        t('sixthMore1.paragraph2'),
        t('sixthMore1.paragraph3'),
        t('sixthMore1.paragraph4'),
      ]
    },
    {
      title: t('sixthMore2.title'),
      paragraphs: [
        t('sixthMore2.paragraph'),
        t('sixthMore2.paragraph2'),
        t('sixthMore2.paragraph3'),
        t('sixthMore2.paragraph4'),
        t('sixthMore2.paragraph5'),
        t('sixthMore2.paragraph6'),
      ]
    },
    {
      title: t('sixthMore3.title'),
      paragraphs: [
        t('sixthMore3.paragraph'),
        t('sixthMore3.paragraph2'),
        t('sixthMore3.paragraph3'),
        t('sixthMore3.paragraph4'),
      ]
    },
    {
      title: t('sixthMore4.title'),
      paragraphs: [
        t('sixthMore4.paragraph'),
        t('sixthMore4.paragraph2'),
        t('sixthMore4.paragraph3'),
        t('sixthMore4.paragraph4'),
        t('sixthMore4.paragraph5')
      ]
    }
  ]

  const items = [
    {
      title: t('sixth.item1.title'),
      text: t('sixth.item1.text'),
      buttonText: t('sixth.more'),
      image: efficiency,
      id: 0
    },
    {
      title: t('sixth.item2.title'),
      text: t('sixth.item2.text'),
      buttonText: t('sixth.more'),
      image: costs,
      id: 1
    },
    {
      title: t('sixth.item3.title'),
      text: t('sixth.item3.text'),
      buttonText: t('sixth.more'),
      image: support,
      id: 2
    },
    {
      title: t('sixth.item4.title'),
      text: t('sixth.item4.text'),
      buttonText: t('sixth.more'),
      image: swords,
      id: 3
    }
  ];

  return (
    <>
      {isModalOpen && <Modal navigation={true} content={content[modalId]} />}
      {isModalOpen && <OverlayModal onClick={() => setIsModalOpen(prev => !prev)} />}

      <ContainerInnerSlide>
        <Text>
          <TitleStart>{t('sixth.title')}</TitleStart>

          <S.Paragraph dangerouslySetInnerHTML={{__html: t('sixth.paragraph')}} />
        </Text>

        <InnerSlide items={items} />
      </ContainerInnerSlide>

      <TopGradient />
    </>
  )
}
