import { useEffect, useState } from 'react'
import { SplideSlide } from '@splidejs/react-splide'

import '@splidejs/splide/dist/css/splide.min.css'
import * as S from './budgeter-style'

import { Gradient, Container } from 'components/shared/styles'
import { Menu } from 'components/menu'
import { BudgeterPage } from 'components/budgeter-page'
import { ReactComponent as ArrowIcon } from  'images/icons/arrow.svg'
import { DataType } from 'budget'

export function Budgeter () {
  const [response, setResponse] = useState<DataType[]>([])
  const [isPageZeroActive, setIsPageZeroActive] = useState(true)
  const [isLastPageActive, setIsLastPageActive] = useState(false)

  useEffect(() => {
    setResponse([
      {
        id: "044cba8b-bac4-457f-bd6f-d3b4d74760ec",
        title: "Autenticação",
        index: null,
        select_type: "single",
        description: "Para login",
        budget_options: [{
          id: "16a5c258-061c-4352-8dc6-b673a9cb4f47",
          name: "Login simples",
          description: "Com e-mail e senha, possui tela de cadastro e todo o fluxo de recuperação de senha via e-mail",
          value: null,
          estimated_hour: 23
        },
        {
          id: "e3038430-c215-4779-ad18-2bdc05f41eac",
          name: "Perfil de usuário",
          description: "Tela de perfil de usuário com visualização e edição de dados ",
          value: null,
          estimated_hour: 20
        },
        {
          id: "c84e53eb-b7ac-42b3-8706-49599281cbb2",
          name: "Login/Registro/Esqueci a Senha/Invite",
          description: "Telas padrão para autenticação do usuário (Criação de conta, login, recuperação de conta e invite - disparo via email para convidar usuário a se cadastrar)",
          value: null,
          estimated_hour: 30
        },
        {
          id: "e23aeac9-3596-44a4-9fbd-317aea5e7e89",
          name: "Login com Redes Socias",
          description: "Social login - Acesso com instagram, facebook, twitter, ...",
          value: null,
          estimated_hour: 30
        },
        {
          id: "e23aeac9-3596-44a4-9fbd-317aea5e7e",
          name: "Login com Redes Socias",
          description: "Social login - Acesso com instagram, facebook, twitter, ...",
          value: null,
          estimated_hour: 30
        },
        {
          id: "e23aeac9-3596-44a4-9fbd-317aea5",
          name: "Login com Redes Socias",
          description: "Social login - Acesso com instagram, facebook, twitter, ...",
          value: null,
          estimated_hour: 30
        },
        {
          id: "e23aeac9-3596-44a4-9fbd-317ae",
          name: "Login com Redes Socias",
          description: "Social login - Acesso com instagram, facebook, twitter, ...",
          value: null,
          estimated_hour: 30
        },
        {
          id: "e23aeac9-3596-44a4-317aea5e7e89",
          name: "Login com Redes Socias",
          description: "Social login - Acesso com instagram, facebook, twitter, ...",
          value: null,
          estimated_hour: 30
        },
        {
          id: "ef2a56e1-9e79-47bd-a97b-780067d5022a",
          name: "Permissão de Acesso",
          description: "Grupos de acesso nos quais os usuários possuem permissões diferentes para acessar/modificar/etc determinada página",
          value: null,
          estimated_hour: 30
        }]
      },
      {
        id: "b628c563-7170-4d2d-8b3e-1eea50d20faf",
        title: "Gerenciamento",
        index: null,
        select_type: "single",
        description: "",
        budget_options: [{
          id: "f902891e-af02-4b55-9b25-14874c86bee9",
          name: "CRUDs",
          description: "Criar, Ler, Editar e Deletar registros (por exemplo, uma tabela que lista todos funcionários, onde posso criar mais, editar ou excuir eles)",
          value: null,
          estimated_hour: 30
        },
        {
          id: "965a4c35-1b9e-40fa-a3f9-1552e1fb0402",
          name: "Calendário (agendamento)",
          description: "Agendar ações e visíualizar em um calendário clicável interno da plataforma (sem integração com calendar)",
          value: null,
          estimated_hour: 40
        }, {
          id: "7898499e-ca7a-42d3-beb1-41a42f779dac",
          name: "Quiz (painel de criação de questionários)",
          description: "Quiz com painel pro usuário gerenciar as perguntas e respostas ",
          value: null,
          estimated_hour: 50
        }]
      },
      {
        id: "8615bcc0-3f46-449c-984b-2557afcd90a9",
        title: "Landing Page",
        index: null,
        select_type: "multiple",
        description: "",
        budget_options: [{
          id: "802db42b-273c-4f98-a140-5a84b15daa76",
          name: "Welcome Screen",
          description: "Tela inicial de boas vindas",
          value: null,
          estimated_hour: 20
        },
        {
          id: "7ccbb055-465e-4ae6-937f-a86e77f78752",
          name: "Criador de landing page simpes ",
          description: "Cria página dentro da plataforma a partir de layout padrão ou inserção de código HTML",
          value: null,
          estimated_hour: 40
        },
        {
          id: "3ff295e9-becc-41c7-a273-0310921e04f6",
          name: "Landing page personalizada (design + criação)",
          description: "Páginas de captura, páginas de apresentação de produtos/serviços, etc",
          value: null,
          estimated_hour: 140
        },
        {
          id: "3ff295e9-becc-41c7-a273-031092",
          name: "Landing page personalizada (design + criação)",
          description: "Páginas de captura, páginas de apresentação de produtos/serviços, etc",
          value: null,
          estimated_hour: 140
        },
        {
          id: "0bf4498d-b75c-4815-be57-c3d4adac48f0",
          name: "Welcome Screen",
          description: "Tela inicial de boas vindas",
          value: null,
          estimated_hour: 20
        }]
      }
    ])
  }, [])

  const activePage = (e: any) => {
    const firstSlide = e.Components.Elements.slides.filter((item: any) => {
      return item.id.includes('slide01') && item.classList.contains('is-active')
    })

    const lastSlide = e.Components.Elements.slides.filter((item: any) => {
      return item.id.includes(e.Components.Elements.slides.length) && item.classList.contains('is-active')
    })

    setIsLastPageActive(lastSlide.length > 0 ? true : false)
    setIsPageZeroActive(firstSlide.length > 0 ? true : false)
  }

  return (
    <Container>
      <Menu />

      <S.Content
        renderControls={() => (
          <S.ArrowsWrapper className="splide__arrows">
            <div>
              <S.Arrow
                style={{ display: isPageZeroActive ? 'none' : 'flex' }}
                className='splide__arrow splide__arrow--prev'
              >
                <ArrowIcon style={{ transform: 'scaleX(-1)' }} />

                Anterior
              </S.Arrow>

              <S.Arrow
                style={{ display: isLastPageActive ? 'none' : 'flex' }}
                className='splide__arrow splide__arrow--next'
              >
                Próximo

                <ArrowIcon />
              </S.Arrow>
            </div>
          </S.ArrowsWrapper>
        )}
        onMove={activePage}
        options={{
          width: '100vw',
          height: '100vh',
          perMove: 1,
          perPage: 1,
          flickPower: 500,
          flickMaxPages: 1,
          easing: 'cubic-bezier(1, 0, 0, 1)',
          speed: 800,
          keyboard: false,
          arrows: false,
          pagination: false,
          waitForTransition: true,
          drag: false,
          wheel: false,
          updateOnMove: true,
          lazyLoad: 'nearby'
        }}
      >
        {response.map((data) => (
          <SplideSlide key={data.id}>
            <BudgeterPage data={data} />
          </SplideSlide>
        ))}
      </S.Content>

      <Gradient />
    </Container>
  )
}
