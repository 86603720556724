import styled from 'styled-components/macro'
import { ReactComponent as InstaIcon } from 'images/icons/instagram.svg'
import { ReactComponent as Logo } from '../../images/kolabs.svg'
import { Link } from 'react-router-dom';

export const MenuContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.04em;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

export const KolabsLogo = styled(Logo)`

`

export const InstagramIcon = styled(InstaIcon)`
  width: 1em;
  height: 1em;
`

export const Menu = styled.ul`
  display: flex;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1em;

  @media (min-width: 800px) {
    width: max-content;
    margin-left: auto;
    gap: 1.984em;
  }
`;

export const MenuItem = styled.a`
  color: #000;
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  letter-spacing: .75px;
`

export const CTAButton = styled(Link)`
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: .75px;
  text-decoration: underline;
  text-transform: uppercase;
  background: -webkit-linear-gradient(309.83deg, #00A8A8 6.1%, #6889FF 13.98%, #CC33FF 65.12%, #FF00E5 104.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const Line = styled.hr`
  height: 1px;
  width: 100%;
  border: 0;
  background: linear-gradient(309.83deg, #00A8A8 6.1%, #6889FF 13.98%, #CC33FF 65.12%, #FF00E5 104.11%);
  margin-top: -5px;
`
