import { BudgetOptions } from 'budget'
import { BudgeterModal } from 'components/budgeter-modal'
import { ModalContext } from 'components/contexts/modal-context'
import { MouseEvent, useContext } from 'react'
import * as S from './budgeter-card-style'

type BudgeterCardProps = {
  data: BudgetOptions
  selected: boolean
}

export function BudgeterCard ({ data, selected }: BudgeterCardProps) {
  const { isBudgeterModalOpen, setIsBudgeterModalOpen, budgeterModalId, setBudgeterModalId } = useContext(ModalContext)

  const handleHelpClick = (event: MouseEvent, id: string) => {
    event.stopPropagation()
    setIsBudgeterModalOpen(true)
    setBudgeterModalId(id)
  }

  return (
    <>
      {isBudgeterModalOpen && budgeterModalId === data.id && (
        <S.ModalWrapper onClick={(e) => e.stopPropagation()}>
          <BudgeterModal />
        </S.ModalWrapper>
      )}

      <S.Container>
        <S.Card selected={selected} />

        <S.Wrapper>
          <S.Caption>{data.name}</S.Caption>

          <S.HelpIcon
            onClick={(event) => handleHelpClick(event, data.id)}
          />
        </S.Wrapper>
      </S.Container>
    </>
  )
}
