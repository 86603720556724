import styled from 'styled-components/macro'
import woman from 'images/woman-bg.webp'
import { BaseContainer } from 'components/shared/styles'

export const Container = styled(BaseContainer)`
  padding: 8em 24px 0;

  > div {
    justify-content: start;
  }

  @media (max-height: 540px) and (min-width: 800px) {
    padding-top: 3em;

    > div {
      justify-content: start;
    }
  }

  @media (min-height: 540px) and (min-width: 800px) {
    padding: 0 4vw 0;

    > div {
      justify-content: center;
    }
  }

  @media (min-height: 560px) and (min-width: 1044px) {
    > div:first-child {
      justify-content: center;
    }

    > div:last-child {
      position: initial;
    }

    flex-direction: row-reverse;
    padding: 0 4vw 0;
    place-content: center;
  }
`

export const Flex = styled(BaseContainer)`
  padding: 0;
  z-index: 0;

  h1 {
    text-align: start;
    white-space: nowrap;
  }

  p {
    text-align: start;
    margin: 1em 0;
    max-width: 600px;
    font-size: .75rem;
  }

  p:first-child {
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: .44px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: 'Rajdhani', sans-serif;

    span {
      font-size: 1.625rem;
      background: -webkit-linear-gradient(309.83deg, #00A8A8 6.1%, #6889FF 13.98%, #CC33FF 65.12%, #FF00E5 104.11%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
    }
  }

  span {
    background: -webkit-linear-gradient(309.83deg, #00A8A8 6.1%, #6889FF 13.98%, #CC33FF 65.12%, #FF00E5 104.11%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
  }

  button {
    margin-top: 30px;
  }

  @media (max-width: 800px) {
    p:last-child {
      span {
        font-size: 1.125rem;
      }
    }
  }

  @media (min-width: 600px) {
    p {
      font-size: .875rem;
    }
  }

  @media (min-width: 800px) {
    p {
      font-size: 1rem;
    }
  }
  @media (min-width: 800px) {
    p {
      font-size: 1rem;
    }
  }

  @media (min-width: 900px) {
    align-self: end;
    display: flex;
    flex-direction: column;

    p {
      text-align: start;
      max-width: 380px;
    }

    button {
      margin-left: auto;
    }
  }
`

export const Illustration = styled.div`
  background-image: url(${woman});
  background-size: 56vh;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  right: 0;
  opacity: .3;
  max-width: 44em;

  @media (max-width: 900px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: 900px) {
    background-position: 0% 100%;
    background-size: 70vh;
    opacity: 1;
  }

  @media (min-width: 1044px) {
    background-size: 80vh;
  }

  @media (min-width: 1280px) {
    background-size: 90vh;
  }

  @media (min-height: 820px) and (min-width: 768px) {
    background-size: contain;
  }

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
`
