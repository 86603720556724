import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import web from 'images/icons/web.svg'
import automation from 'images/icons/automation.svg'
import bigdata from 'images/icons/bigdata.svg'
import cloud from 'images/icons/cloud.svg'

import { InnerSlide } from 'components/inner-slide'
import { Modal } from 'components/modal'
import { ModalContext } from 'components/contexts/modal-context'
import {
  ContainerInnerSlide,
  OverlayModal,
  TitleStart,
  Text,
  TopGradient
} from 'components/shared/styles'
import * as S from './technologies-style'

export function Technologies () {
  const { t } = useTranslation()
  const { isModalOpen, setIsModalOpen, modalId } = useContext(ModalContext)

  const content = [
    {
      title: t('fifthMore1.title'),
      paragraphs: [
        t('fifthMore1.paragraph'),
        t('fifthMore1.paragraph2'),
        t('fifthMore1.paragraph3'),
        t('fifthMore1.paragraph4'),
      ]
    },
    {
      title: t('fifthMore2.title'),
      paragraphs: [
        t('fifthMore2.paragraph'),
        t('fifthMore2.paragraph2'),
        t('fifthMore2.paragraph3'),
        t('fifthMore2.paragraph4'),
        t('fifthMore2.paragraph5'),
        t('fifthMore2.paragraph6'),
      ]
    },
    {
      title: t('fifthMore3.title'),
      paragraphs: [
        t('fifthMore3.paragraph'),
        t('fifthMore3.paragraph2'),
        t('fifthMore3.paragraph3'),
        t('fifthMore3.paragraph4'),
        t('fifthMore3.paragraph5'),
      ]
    },
    {
      title: t('fifthMore4.title'),
      paragraphs: [
        t('fifthMore4.paragraph'),
        t('fifthMore4.paragraph2'),
        t('fifthMore4.paragraph3'),
        t('fifthMore4.paragraph4'),
        t('fifthMore4.paragraph5'),
      ]
    }
  ]

  const items = [
    {
      title: t('fifth.item1.title'),
      text: t('fifth.item1.text'),
      buttonText: t('fifth.more'),
      image: web,
      id: 0
    },
    {
      title: t('fifth.item2.title'),
      text: t('fifth.item2.text'),
      buttonText: t('fifth.more'),
      image: automation,
      id: 1
    },
    {
      title: t('fifth.item3.title'),
      text: t('fifth.item3.text'),
      buttonText: t('fifth.more'),
      image: bigdata,
      id: 2
    },
    {
      title: t('fifth.item4.title'),
      text: t('fifth.item4.text'),
      buttonText: t('fifth.more'),
      image: cloud,
      id: 3
    }
  ];

  return (
    <>
      {isModalOpen && <Modal navigation={true} content={content[modalId]} />}
      {isModalOpen && <OverlayModal onClick={() => setIsModalOpen(prev => !prev)} />}

      <ContainerInnerSlide>
        <Text>
          <TitleStart>{t('fifth.title')}</TitleStart>

          <S.Paragraph dangerouslySetInnerHTML={{__html: t('fifth.paragraph')}} />
        </Text>

        <InnerSlide items={items} />
      </ContainerInnerSlide>

      <TopGradient />
    </>
  )
}
