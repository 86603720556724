import { Button } from 'components/shared/styles'
import * as S from './card-style'
import { ModalContext } from 'components/contexts/modal-context'
import { useContext } from 'react'

type CardProps = {
  title: string
  text: string
  buttonText: string
  image: string
  id: number
}

export function Card ({ title, text, buttonText, image, id }: CardProps) {
  const { setIsModalOpen, setModalId } = useContext(ModalContext)

  return (
    <S.Container>
      <div>
        <S.WrapperImage>
          <S.Title>{title}</S.Title>

          <img data-splide-lazy={image} src={image} alt="Icone" />
        </S.WrapperImage>

        <S.TextDescriptive>{text}</S.TextDescriptive>
      </div>

      <Button onClick={() => {
        setIsModalOpen(prev => !prev)
        setModalId(id)
      }}>
        <div />

        {buttonText}
      </Button>

      <S.Overlay />
    </S.Container>
  )
}
